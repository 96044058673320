.Burger,
.Burger::after,
.Burger::before {
	background: currentColor;
	height: 0.125rem;
	width: 100%;
}

.Burger {
	display: inline-block;
	margin: 0 .335rem 0 0;
	position: relative;
	vertical-align: top;
	width: 1rem;
	
	&::after,
	&::before {
		content: '';
		position: absolute;
	}
	
	&::after {
		top: 0.5rem;
	}
	
	&::before {
		top: 0.25rem;
	}
}