/**
 * 1. Fluid images for responsive purposes.
 * 2. Offset `alt` text from surrounding copy.
 * 3. Setting `vertical-align` removes the whitespace that appears under `img`
 *    elements when they are dropped into a page as-is. Safer alternative to
 *    using `display: block;`.
 */
img,
svg {
  max-width: 100%; /* [1] */
  font-style: italic; /* [2] */
  vertical-align: middle; /* [3] */
}



/**
 * 1. Google Maps breaks if `max-width: 100%` acts upon it; use their selector
 *    to remove the effects.
 * 2. If a `width` and/or `height` attribute have been explicitly defined, let’s
 *    not make the image fluid.
 */
.gm-style img, /* [1] */
img[width], /* [2] */
img[height] {  /* [2] */
  max-width: none;
}

/* hack for firefox not respecting svg widths */
@-moz-document url-prefix() {
  img[src$="svg"] {
     width: 100%;
  }
}
