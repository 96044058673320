.u-float-left { float: left; }
.u-float-right { float: right; }
.u-float-none { float: none; }
.u-clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

@media @mq-md {
  .md\:u-float-left { float: left; }
  .md\:u-float-right { float: right; }
  .md\:u-float-none { float: none; }
}
