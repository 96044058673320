.Button {
	-webkit-appearance: none;
	background: transparent;
	border: 1px solid transparent;
	display: inline-block;
	color: #fff;
	cursor: pointer;
	font-family: @heading-font-family;
	font-size: 1rem;
	font-weight: 700;
	line-height: 1;
	margin: 0;
	padding: .579em .833em;
	position: relative;
	text-align: center;
	text-shadow: 0 1px 1px rgba(0,0,0,.25);
	text-transform: uppercase;
	vertical-align: middle;
	white-space: normal;

  /**
  * Fix a Firefox bug whereby `input type="submit"` gains 2px extra padding.
  */
	&::-moz-focus-inner {
    border:  0;
    padding: 0;
	}
}

.Button:hover,
.Button:focus,
.Button:active {
  text-decoration: none;
}

.Button:disabled,
.Button.is-disabled {
  cursor: default;
  opacity: 0.6;
}

.Button--primary {
	background: @blue;
}

.Button--lg {
	font-size: 1.44rem;
}

.Button--full {
	display: block;
	width: 100%;
}

.Button--whiteOpaque {
	background-color: rgba(255,255,255,.2);
}

@keyframes expandingUnderline {
	from {
		background: currentColor;
		width: 0;
	}
	to {
		width: 100%;
	}
}

.Button--underline {
	background: none;

	&::before {
		background: currentColor;
		bottom: 0;
		content: '';
		height: 3px;
		left: 50%;
		position: absolute;
		transform: translateX(-50%);
		width: 100%;
	}

	&:hover,
	&:focus,
	&:active {
		&::before {
			animation: expandingUnderline 1s;
			background: @green;
		}
	}
}
