.u-giga {
	font-size: 3.583rem;
}

.u-mega {
	font-size: 2.986rem;
}

h1,
.u-alpha {
	font-size: 2.074rem;
}

h2,
.u-beta {
	font-size: 1.728rem;
}

h3,
.u-gamma {
	font-size: 1.44rem;
}

h4,
h5,
.u-delta,
.u-epsilon {
	font-size: 1.2rem;
}

h5,
.u-zeta {
	font-size: 1rem;
}

.u-deci {
	font-size: 0.833rem;
}

.u-centi {
	font-size: 0.694rem;
}

.u-milli {
	font-size: 0.579rem;
}

@media @mq-md {
	.u-giga {
		font-size: 4.3rem;
	}
	
	.u-mega {
		font-size: 3.583rem;
	}
	
	h1,
	.u-alpha {
		font-size: 2.488rem;
	}
	
	h2,
	.u-beta {
		font-size: 2.074rem;
	}
	
	h3,
	.u-gamma {
		font-size: 1.728rem;
	}
	
	h4,
	.u-delta {
		font-size: 1.44rem;
	}
	
	h6,
	.u-zeta {
		font-size: 1rem;
	}
}