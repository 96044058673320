/* Tools - site-wide mixins and functions */
/*
 * Clearfix: contain floats
 *
 * For modern browsers
 * 1. The space content is one way to avoid an Opera bug when the
 *    `contenteditable` attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of elements
 *    that receive the `clearfix` class.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 */
.u-cf::before,
.u-cf::after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}
.u-cf::after {
  clear: both;
}
.GreenGradient {
  background: linear-gradient(to bottom, #35ad22 0%, #3cc326 50%);
}
/* Generic - low-specificity, far-reaching rulesets (e.g. resets) */
/**
 * Set the global `box-sizing` state to `border-box`.
 *
 * css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
 * paulirish.com/2012/box-sizing-border-box-ftw
 */
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
/**
 * Where `margin-bottom` is concerned,this value will be the same as the
 * base line-height. This allows us to keep a consistent vertical rhythm.
 * As per: csswizardry.com/2012/06/single-direction-margin-declarations
 */
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
dl,
blockquote,
p,
address,
hr,
table,
fieldset,
figure,
pre {
  margin-bottom: 1.2rem;
  margin-top: 0;
}
/**
 * Where `margin-left` is concerned we want to try and indent certain elements
 * by a consistent amount. Define that amount once,here.
 */
ul,
ol,
dd {
  margin-left: 1.728rem;
  padding-left: 1.728rem;
}
@media (min-width: 60.063em) {
  ul,
  ol,
  dd {
    margin-left: 2.986rem;
  }
}
/* Base - unclassed HTML elements */
blockquote {
  margin: 0;
}
figure {
  margin-left: 0;
  margin-right: 0;
}
form {
  margin: 0 0 1.2rem 0;
}
lable {
  display: inline-block;
}
input[type="text"],
input[type="password"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="month"],
input[type="week"],
input[type="email"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="color"],
textarea {
  background-color: #efefef;
  border: none;
  border-radius: 0;
  display: block;
  font-size: 1rem;
  line-height: 1.2;
  padding: 0.488rem;
  width: 100%;
}
input[type="radio"],
input[type="checkbox"] {
  margin-right: 4px;
  vertical-align: middle;
}
textarea {
  max-width: 100%;
}
select {
  -webkit-appearance: none !important;
  background-color: #efefef;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeD0iMTJweCIgeT0iMHB4IiB3aWR0aD0iMjRweCIgaGVpZ2h0PSIzcHgiIHZpZXdCb3g9IjAgMCA2IDMiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDYgMyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBvbHlnb24gcG9pbnRzPSI1Ljk5MiwwIDIuOTkyLDMgLTAuMDA4LDAgIi8+PC9zdmc+);
  background-position: left center;
  background-repeat: no-repeat;
  border: none;
  border-radius: 0;
  height: 2.25em;
  padding: 0.488rem 0.488rem 0.488rem 2rem;
  width: 100%;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'rift', sans-serif;
  font-weight: 700;
  line-height: 1;
  margin: 0;
  text-transform: uppercase;
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}
hr {
  border: none;
  border-top: 1px solid #b3b3b3;
}
/**
 * 1. Fluid images for responsive purposes.
 * 2. Offset `alt` text from surrounding copy.
 * 3. Setting `vertical-align` removes the whitespace that appears under `img`
 *    elements when they are dropped into a page as-is. Safer alternative to
 *    using `display: block;`.
 */
img,
svg {
  max-width: 100%;
  /* [1] */
  font-style: italic;
  /* [2] */
  vertical-align: middle;
  /* [3] */
}
/**
 * 1. Google Maps breaks if `max-width: 100%` acts upon it; use their selector
 *    to remove the effects.
 * 2. If a `width` and/or `height` attribute have been explicitly defined, let’s
 *    not make the image fluid.
 */
.gm-style img,
img[width],
img[height] {
  /* [2] */
  max-width: none;
}
/* hack for firefox not respecting svg widths */
@-moz-document url-prefix() {
  img[src$="svg"] {
    width: 100%;
  }
}
a {
  color: #009ace;
  text-decoration: none;
}
li > ul,
li > ol {
  margin-bottom: 0;
}
/**
 * High-, page-level styling.
 *
 * 1. Set the default `font-size` and `line-height` for the entire project,
 *    sourced from our default variables. The `font-size` is calculated to exist
 *    in ems, the `line-height` is calculated to exist unitlessly.
 * 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 * 3. Ensure the page always fills at least the entire height of the viewport.
 * 4. Prevent certain mobile browsers from automatically zooming fonts.
 * 5. Fonts on OSX will look more consistent with other systems that do not
 *    render text using sub-pixel anti-aliasing.
 */
html {
  font-family: 'pt-sans', sans-serif;
  font-size: 16px;
  /* [1] */
  font-weight: 300;
  line-height: 1.44;
  /* [1] */
  background-color: #f5f5f5;
  color: #444;
  overflow-y: scroll;
  /* [2] */
  min-height: 100%;
  /* [3] */
  -webkit-text-size-adjust: 100%;
  /* [4] */
  -ms-text-size-adjust: 100%;
  /* [4] */
  -moz-osx-font-smoothing: grayscale;
  /* [5] */
  -webkit-font-smoothing: antialiased;
  /* [5] */
}
body {
  overflow: hidden;
}
*,
*::before,
*::after {
  border-width: 0;
  border-style: solid;
  border-color: currentColor;
}
table {
  width: 100%;
}
th,
td {
  text-align: left;
  vertical-align: top;
}
thead > tr > th,
thead > tr > td {
  font-family: 'rift', sans-serif;
  vertical-align: bottom;
  text-transform: uppercase;
}
thead > tr > th,
thead > tr > td,
tbody > tr > th,
tbody > tr > td {
  padding: 0.5rem;
}
/* Objects - objects, abstractions, and design patterns (e.g. .media{}) */
.Box {
  display: block;
  padding: 1rem;
}
.Box--sm {
  padding: 0.335em;
}
.Box--flush {
  padding: 0;
}
.Box--black {
  background-color: #000;
  color: #e6e6e6;
}
.Box--greenGradient {
  color: #fff;
  background: linear-gradient(to bottom, #35ad22 0%, #3cc326 50%);
}
.Box--flushH {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.Box--card {
  border: 1px solid currentColor;
}
.Button {
  -webkit-appearance: none;
  background: transparent;
  border: 1px solid transparent;
  display: inline-block;
  color: #fff;
  cursor: pointer;
  font-family: 'rift', sans-serif;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1;
  margin: 0;
  padding: 0.579em 0.833em;
  position: relative;
  text-align: center;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
  text-transform: uppercase;
  vertical-align: middle;
  white-space: normal;
  /**
  * Fix a Firefox bug whereby `input type="submit"` gains 2px extra padding.
  */
}
.Button::-moz-focus-inner {
  border: 0;
  padding: 0;
}
.Button:hover,
.Button:focus,
.Button:active {
  text-decoration: none;
}
.Button:disabled,
.Button.is-disabled {
  cursor: default;
  opacity: 0.6;
}
.Button--primary {
  background: #009ace;
}
.Button--lg {
  font-size: 1.44rem;
}
.Button--full {
  display: block;
  width: 100%;
}
.Button--whiteOpaque {
  background-color: rgba(255, 255, 255, 0.2);
}
@keyframes expandingUnderline {
  from {
    background: currentColor;
    width: 0;
  }
  to {
    width: 100%;
  }
}
.Button--underline {
  background: none;
}
.Button--underline::before {
  background: currentColor;
  bottom: 0;
  content: '';
  height: 3px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 100%;
}
.Button--underline:hover::before,
.Button--underline:focus::before,
.Button--underline:active::before {
  animation: expandingUnderline 1s;
  background: #44d62c;
}
.Container {
  display: block;
  padding: 1rem;
  margin: 0 auto;
  max-width: 60rem;
  position: relative;
  width: 100%;
  z-index: 1;
}
.Container::before,
.Container::after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}
.Container::after {
  clear: both;
}
.Container--wide {
  max-width: 100rem;
}
.Container--full {
  max-width: none !important;
}
.Container--black {
  background: #000;
}
.Container--black > * {
  color: #fff;
}
.Container--black > :first-child {
  margin-top: 0 !important;
}
.Container--black .Subtitle {
  color: #fff;
}
.Container--black .Box--card {
  border-color: #444;
}
.Container--black .Media--connected .MediaFigure::before {
  border-bottom-color: #000;
}
.Container--black .Media--connected .MediaBody + .MediaFigure::before {
  border-bottom-color: transparent;
  border-top-color: #000;
}
.Container--black .Media--flag .MediaFigure::before {
  border-bottom-color: transparent;
  border-right-color: #000;
}
.Container--black .Media--flag .MediaBody + .MediaFigure::before {
  border-bottom-color: transparent;
  border-left-color: #000;
}
.Container--black .Media h1,
.Container--black .Media h2,
.Container--black .Media h3,
.Container--black .Media h4,
.Container--black .Media h5,
.Container--black .Media h6 {
  color: #A2C75A;
}
.Container--flushT {
  padding-top: 0 !important;
}
.Container--flushH {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.Container--flush {
  padding: 0 !important;
}
.Container--flat::after,
.Container--flat::before {
  background: none;
}
@media (min-width: 36.063em) {
  .Container {
    padding: 2.4888rem 1.2em;
  }
  .Container--black .sm-Media--flag .MediaFigure::before {
    border-color: transparent;
    border-right-color: #000;
  }
  .Container--black .sm-Media--flag .MediaBody + .MediaFigure::before {
    border-color: transparent;
    border-left-color: #000;
  }
}
@media (min-width: 60.063em) {
  .Container--black .md-Media--flag .MediaFigure::before {
    border-color: transparent;
    border-right-color: #000;
  }
  .Container--black .md-Media--flag .MediaBody + .MediaFigure::before {
    border-color: transparent;
    border-left-color: #000;
  }
}
/**
 * Flexible media embeds
 *
 * For use with media embeds – such as videos, slideshows, or even images –
 * that need to retain a specific aspect ratio but adapt to the width of their
 * containing element.
 *
 * Based on: http://alistapart.com/article/creating-intrinsic-ratios-for-video
 */
.FlexEmbed {
  display: block;
  overflow: hidden;
  position: relative;
}
/**
 * The aspect-ratio hack is applied to an empty element because it allows
 * the component to respect `max-height`. Default aspect ratio is 1:1.
 */
.FlexEmbed-ratio {
  display: block;
  padding-bottom: 100%;
  width: 100%;
}
/**
 * Modifier: 3:1 aspect ratio
 */
.FlexEmbed-ratio--3by1 {
  padding-bottom: calc(100% / 3);
}
/**
 * Modifier: 2:1 aspect ratio
 */
.FlexEmbed-ratio--2by1 {
  padding-bottom: 50%;
}
.FlexEmbed-ratio--191by100 {
  padding-bottom: 52.36%;
}
/**
 * Modifier: 16:9 aspect ratio
 */
.FlexEmbed-ratio--16by9 {
  padding-bottom: 56.25%;
}
/**
 * Modifier: 4:3 aspect ratio
 */
.FlexEmbed-ratio--4by3 {
  padding-bottom: 75%;
}
/**
 * Fit the content to the aspect ratio
 */
.FlexEmbed-content {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}
.FlexEmbed-content img {
  left: 50%;
  max-height: 100%;
  position: relative;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}
@media (min-width: 36.063em) {
  .sm-FlexEmbed-ratio--2by1 {
    padding-bottom: 50%;
  }
  .sm-FlexEmbed-ratio--16by9 {
    padding-bottom: 56.25%;
  }
  .sm-FlexEmbed-ratio--1by2 {
    padding-bottom: 200%;
  }
}
@media (min-width: 60.063em) {
  .md-FlexEmbed-ratio--2by1 {
    padding-bottom: 50%;
  }
  .md-FlexEmbed-ratio--16by9 {
    padding-bottom: 56.25%;
  }
  .md-FlexEmbed-ratio--1by2 {
    padding-bottom: 200%;
  }
}
/* Grid container
   ========================================================================== */
/**
 * All content must be contained within child `Grid-cell` elements.
 *
 * 1. Account for browser defaults of elements that might be the root node of
 *    the component.
 */
.Grid {
  display: flex;
  /* 1 */
  flex-flow: row wrap;
  margin: 0;
  /* 1 */
  padding: 0;
  /* 1 */
}
/* With gutters */
.Grid--withGutter {
  margin: -16px 0 0 -16px;
}
.Grid--withGutter > .Grid-cell {
  padding: 16px 0 0 16px;
}
/* Alignment per row */
.Grid--alignTop {
  align-items: flex-start;
}
.Grid--alignBottom {
  align-items: flex-end;
}
.Grid--alignCenter {
  align-items: center;
}
.Grid--justifyCenter {
  justify-content: center;
}
/* Alignment per cell */
.Grid-cell--alignTop {
  align-self: flex-start;
}
.Grid-cell--alignBottom {
  align-self: flex-end;
}
.Grid-cell--alignCenter {
  align-self: center;
}
/* Grid cell
   ========================================================================== */
/**
 * No explicit width by default. Rely on combining `Grid-cell` with a dimension
 * utility or a component class that extends 'Grid'.
 *
 * 1. Set flex items to full width by default
 * 2. Fix issue where elements with overflow extend past the
 *    `Grid-cell` container - https://git.io/vw5oF
 */
.Grid-cell {
  box-sizing: inherit;
  flex-basis: 100%;
  /* 1 */
  min-width: 0;
  /* 2 */
}
/**
 * Modifier: horizontally center one unit
 * Set a specific unit to be horizontally centered. Doesn't affect
 * any other units. Can still contain a child `Grid` object.
 */
.Grid-cell--center {
  margin: 0 auto;
}
.Layout {
  display: flex;
  flex-direction: column;
}
.Layout-cell {
  flex: 1;
  position: relative;
}
.Layout-cell > .Layout {
  flex: 1;
}
.Layout-cell--left {
  margin-right: auto;
}
.Layout-cell--right {
  margin-left: auto;
}
.Layout-cell--middle {
  align-self: center;
}
.Layout-cell--fit {
  flex: 0 0 auto;
}
.Layout--row {
  flex-direction: row;
  flex-wrap: wrap;
}
.Layout--row .Layout-rail {
  flex: 0 0 20em;
}
.Layout-rail .Widget:not(:first-child) {
  margin-top: 1.2rem;
}
.Layout--top {
  align-items: flex-start;
}
.Layout--center {
  align-items: center;
  justify-content: center;
}
.Layout--center > .Layout-cell {
  flex: none;
}
.Layout--stretch {
  align-items: stretch;
}
.Layout--bottom {
  align-items: flex-end;
}
.Layout--withGutter {
  margin: -1rem -1rem 0;
}
.Layout--withGutter > .Layout-cell {
  padding: 1rem 1rem 0;
}
.Layout--withGutter .EntryFigure-caption {
  padding-left: 0;
  padding-right: 0;
}
@media (min-width: 36.063em) {
  .Layout--withGutter,
  .sm-Layout--withGutter {
    margin: -1.2rem -1.2rem 0;
  }
  .Layout--withGutter > .Layout-cell,
  .sm-Layout--withGutter > .Layout-cell {
    padding: 1.2rem 1.2rem 0;
  }
  .Layout--withGutter .EntryFigure-caption,
  .sm-Layout--withGutter .EntryFigure-caption {
    padding-left: 0;
    padding-right: 0;
  }
  .sm-Layout--noGutter {
    margin: 0;
  }
  .sm-Layout--noGutter > .Layout-cell {
    padding: 0;
  }
  .sm-Layout--noGutter .EntryFigure-caption {
    padding-left: 0.833rem;
    padding-right: 0.833rem;
  }
  .sm-Layout--row {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .sm-Layout--row .Layout-rail {
    flex: 0 0 20em;
  }
  .sm-Layout--column {
    flex-direction: column;
    flex-wrap: nowrap;
  }
  .sm-Layout-rail {
    flex: 0 0 20em;
  }
  .sm-Layout--center {
    align-items: center;
    justify-content: center;
  }
  .sm-Layout--center > .Layout-cell {
    flex: none;
  }
}
@media (min-width: 60.063em) {
  .md-Layout--withGutter {
    margin: -1.2rem -1.2rem 0;
  }
  .md-Layout--withGutter > .Layout-cell {
    padding: 1.2rem 1.2rem 0;
  }
  .md-Layout--withGutter .EntryFigure-caption {
    padding-left: 0;
    padding-right: 0;
  }
  .md-Layout--noGutter {
    margin: 0;
  }
  .md-Layout--noGutter > .Layout-cell {
    padding: 0;
  }
  .md-Layout--noGutter .EntryFigure-caption {
    padding-left: 0.833rem;
    padding-right: 0.833rem;
  }
  .md-Layout--row {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .md-Layout--row .Layout-rail {
    flex: 0 0 20em;
  }
  .md-Layout--column {
    flex-direction: column;
    flex-wrap: nowrap;
  }
  .md-Layout-rail {
    flex: 0 0 20em;
  }
  .md-Layout--center {
    align-items: center;
    justify-content: center;
  }
  .md-Layout--center > .Layout-cell {
    flex: none;
  }
}
@media (min-width: 85.063em) {
  .lg-Layout--withGutter {
    margin: -1.2rem -1.2rem 0;
  }
  .lg-Layout--withGutter > .Layout-cell {
    padding: 1.2rem 1.2rem 0;
  }
  .lg-Layout--withGutter .EntryFigure-caption {
    padding-left: 0;
    padding-right: 0;
  }
  .lg-Layout--noGutter {
    margin: 0;
  }
  .lg-Layout--noGutter > .Layout-cell {
    padding: 0;
  }
  .lg-Layout--noGutter .EntryFigure-caption {
    padding-left: 0.833rem;
    padding-right: 0.833rem;
  }
  .lg-Layout--row {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .lg-Layout--column {
    flex-direction: column;
    flex-wrap: nowrap;
  }
  .lg-Layout-rail {
    flex: 0 0 20em;
  }
  .lg-Layout--center {
    align-items: center;
    justify-content: center;
  }
  .lg-Layout--center > .Layout-cell {
    flex: none;
  }
}
/**
 * The list-inline object simply displays a list of items in one line.
 */
.ListInline {
  margin: 0 0 1.2rem;
  padding: 0;
  list-style: none;
}
.ListInline > .ListInline-item {
  display: inline-block;
  line-height: 1.2;
  margin-right: 8px;
}
.Listing {
  border-bottom: 1px solid #b3b3b3;
  padding-bottom: 1rem;
}
.Media {
  display: block;
  margin-bottom: 1.2rem;
}
.Media h1,
.Media h2,
.Media h3,
.Media h4,
.Media h5,
.Media h6 {
  margin-bottom: 0.335rem;
}
.Media > :first-child {
  margin-bottom: 0.694rem;
}
.MediaFigure--block {
  display: flex;
  justify-content: center;
  height: 150px;
}
.MediaFigure--16by9::before {
  padding-top: 56.25%;
}
.MediaFigure--sign {
  border: 2px solid;
  margin-left: auto;
  margin-right: auto;
  max-width: 350px;
  padding: 1rem;
  width: 80%;
}
.MediaBody {
  display: block;
  margin: 0 auto;
  max-width: 40rem;
}
.MediaBody :first-child {
  margin-top: 0;
}
.MediaBody :last-child {
  margin-bottom: 0;
}
.MediaBody .MediaFigure--block {
  max-height: 200px;
}
.MediaBody--full {
  max-width: none;
}
.Media--card {
  max-width: 400px;
}
.Media--stretch {
  align-items: stretch !important;
}
.Media--connected .MediaFigure {
  position: relative;
}
.Media--connected .MediaFigure::before {
  bottom: -1px;
  border: solid transparent;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #fff;
  border-width: 60px;
  border-left-width: 40px;
  border-right-width: 40px;
  content: '';
  display: block;
  height: 0;
  left: 50%;
  margin-left: -40px;
  position: absolute;
  width: 0;
}
.Media--connected .MediaFigure::after {
  background-image: url('/img/abc-arrow--up.png');
  background-repeat: no-repeat;
  background-size: 40px;
  bottom: 0;
  content: '';
  display: block;
  height: 40px;
  left: 50%;
  margin-left: -20px;
  position: absolute;
  width: 40px;
}
.Media--connected .MediaBody + .MediaFigure::before {
  bottom: auto;
  border-bottom-color: transparent;
  border-top-color: #fff;
  top: 0;
}
.Media--connected .MediaBody + .MediaFigure::after {
  bottom: auto;
  top: 0;
  transform: rotate(180deg);
}
.Media--flag {
  display: flex;
  align-items: center;
}
.Media--flag > :first-child {
  margin-bottom: 0;
  padding-right: 1rem;
}
.Media--flag .MediaFigure::before {
  bottom: auto;
  border-bottom-color: transparent;
  border-bottom-width: 50px;
  border-right-width: 80px;
  border-right-color: #f5f5f5;
  border-top-width: 50px;
  left: auto;
  margin-top: -50px;
  right: 0;
  top: 50%;
}
.Media--flag .MediaFigure::after {
  bottom: auto;
  left: auto;
  margin-top: -20px;
  right: 0;
  top: 50%;
  transform: rotate(-90deg);
}
.Media--flag .MediaBody {
  flex: 1;
}
.Media--flag .MediaBody + .MediaFigure::before {
  border-left-color: #f5f5f5;
  border-right-color: transparent;
  left: 0;
  right: auto;
  top: 50%;
}
@media (min-width: 36.063em) {
  .sm-Media--flag {
    align-items: center;
    display: flex;
  }
  .sm-Media--flag > :first-child {
    margin-bottom: 0;
    padding-right: 1rem;
  }
  .sm-Media--flag .MediaFigure::before {
    bottom: auto;
    border-bottom-color: transparent;
    border-right-color: #fff;
    left: auto;
    margin-top: -60px;
    right: 0;
    top: 50%;
  }
  .sm-Media--flag .MediaBody {
    flex: 1;
  }
  .sm-Media--flag .MediaBody + .MediaFigure::before {
    border-left-color: #fff;
    border-right-color: transparent;
    left: 0;
    right: auto;
    top: 50%;
  }
}
@media (min-width: 60.063em) {
  .md-Media--flag {
    align-items: center;
    display: flex;
  }
  .md-Media--flag > :first-child {
    margin-bottom: 0;
    padding-right: 1rem;
  }
  .md-Media--flag .MediaFigure::before {
    bottom: auto;
    border-bottom-color: transparent;
    border-bottom-width: 40px;
    border-right-width: 60px;
    border-right-color: #f5f5f5;
    border-top-width: 40px;
    left: auto;
    margin-top: -40px;
    right: 0;
    top: 50%;
  }
  .md-Media--flag .MediaFigure::after {
    bottom: auto;
    left: auto;
    margin-top: -20px;
    right: 1rem;
    top: 50%;
    transform: rotate(-90deg);
  }
  .md-Media--flag .MediaFigure:first-child::before {
    right: 1rem;
  }
  .md-Media--flag .MediaBody {
    flex: 1;
  }
  .md-Media--flag .MediaBody + .MediaFigure::before {
    border-left-color: #fff;
    border-left-width: 60px;
    border-right-color: transparent;
    left: 0;
    margin-left: 0;
    right: auto;
    top: 50%;
  }
  .md-Media--flag .MediaBody + .MediaFigure::after {
    left: 0;
    margin-left: 0;
    top: 50%;
    transform: rotate(90deg);
  }
}
.Play,
.Play.Play--sm {
  bottom: 0;
  border: 8px solid;
  color: #fff;
  cursor: pointer;
  display: block;
  left: 0;
  margin: 1.5%;
  position: absolute;
  text-align: center;
  top: 0;
  width: 97%;
  z-index: 1;
}
.Play::before,
.Play.Play--sm::before {
  border: 8px solid;
  border-radius: 100%;
  content: '';
  display: block;
  height: 72px;
  left: 50%;
  margin: -36px 0 0 -38px;
  position: absolute;
  top: 50%;
  width: 72px;
}
.Play-headline {
  font-size: 2.488rem;
  padding: 1rem;
  text-shadow: 0 2px 1px rgba(0, 0, 0, 0.5);
}
.PlayButton,
.Play--sm .PlayButton {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 36px;
}
@media (min-width: 60.063em) {
  .Play {
    border-width: 12px;
  }
  .Play::before {
    border-width: 12px;
    height: 144px;
    margin: -72px 0 0 -76px;
    width: 144px;
  }
  .PlayButton {
    width: 72px;
  }
}
.o-prose > h1,
.o-prose > h2,
.o-prose > h3,
.o-prose > h4,
.o-prose > h5,
.o-prose > h6 {
  margin-top: 1em;
  margin-bottom: 1rem;
}
.Subtitle {
  color: rgba(0, 0, 0, 0.5);
  font-size: 0.833em;
  font-weight: 400;
  text-transform: uppercase;
}
.Table {
  margin: 1em 0;
  width: 100%;
}
/* force tables into having equal-width columns */
.Table--fixed {
  table-layout: fixed;
}
/* tightly packed cells */
.Table--compact th,
.Table--compact td {
  padding: 0.402em;
}
/* lightly packed cells */
.Table--cosy th,
.Table--cosy td {
  padding: 0.694em;
}
/* widely padded cells */
.Table--comfy th,
.Table--comfy td {
  padding: 1.44em;
}
/* add borders to table rows */
.Table--rows tbody > tr:nth-child(odd) th,
.Table--rows tbody > tr:nth-child(odd) td {
  background: rgba(0, 0, 0, 0.07);
}
/* add borders to table columns */
.Table--columns {
  border-left-width: 1px;
}
.Table--columns,
.Table--columns th,
.Table--columns td {
  border: 0 solid #808080;
}
.Table--columns th,
.Table--columns td {
  border-right-width: 1px;
}
.TableOverflow {
  display: block;
  overflow-x: auto;
  width: 100%;
}
/* Components - discrete, complete chunks of UI (e.g. .carousel{}) */
.Banner {
  background: #000;
  overflow: hidden;
  position: relative;
  padding: 0;
}
.Banner .Nav,
.Banner .Callout {
  position: relative;
  z-index: 2;
}
.Banner-logo {
  background: #000;
  margin: 0;
  padding: 16px 0;
  position: relative;
  text-align: center;
  z-index: 3;
}
.Banner-logo img {
  max-width: 300px;
  width: 100%;
}
.Banner-heading {
  color: #fff;
  font-family: 'rift', sans-serif;
  margin: 0 auto;
  max-width: 60rem;
  padding: 0;
  text-align: center;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.65);
  text-transform: uppercase;
  z-index: 1;
}
.Banner-title {
  padding-bottom: 1rem;
  padding-top: 7.43rem;
}
.Banner--tall {
  height: 100vh;
  max-height: 960px;
}
@media (min-width: 60em) {
  /*.Banner-logo {
		box-shadow: 0 3px 2px -1px rgba(0,0,0,.5);
		left: 0;
		margin: 0 auto;
		padding: 16px;
		position: absolute;
		right: 0;
		top: 0;
		width: 320px;
	}*/
}
.BlockGrid {
  display: flex;
  flex-wrap: wrap;
}
.BlockGrid:hover .BlockGrid-action {
  opacity: 0.5;
}
.BlockGrid-item {
  position: relative;
}
.BlockGrid-item:target {
  padding-bottom: calc(56.25% + 2rem);
}
.BlockGrid-item:target .BlockGrid-action {
  opacity: 1;
}
.BlockGrid-item:hover .BlockGrid-action,
.BlockGrid-item:focus .BlockGrid-action,
.BlockGrid-item:active .BlockGrid-action {
  opacity: 1;
}
.BlockGrid-action {
  display: block;
  position: relative;
}
.BlockGrid-item:target .BlockGrid-action::before {
  bottom: -1px;
  border: solid transparent;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #fff;
  border-width: 60px;
  border-left-width: 40px;
  border-right-width: 40px;
  content: '';
  display: block;
  height: 0;
  left: 50%;
  margin-left: -40px;
  position: absolute;
  width: 0;
  z-index: 1;
}
.BlockGrid-item:target .BlockGrid-action::after {
  background-image: url('/img/abc-arrow--up.png');
  background-repeat: no-repeat;
  background-size: 40px;
  bottom: 0;
  content: '';
  display: block;
  height: 40px;
  left: 50%;
  margin-left: -20px;
  position: absolute;
  width: 40px;
  z-index: 1;
}
.BlockGrid-detail {
  background: linear-gradient(to bottom, #fff 0, #e6e6e6 100%);
  bottom: 0;
  display: none;
  padding: 1rem 0;
  left: 0;
  position: absolute;
  width: 100%;
}
.BlockGrid-item:target .BlockGrid-detail {
  display: block;
}
.BlockGrid-close,
.BlockGrid-nav,
.BlockGrid-nav--prev::before,
.BlockGrid-nav--next::before {
  width: 60px;
}
.BlockGrid-close {
  background: #fff;
  color: #000;
  display: block;
  font-size: 33px;
  font-weight: 700;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 1rem;
  /* considers the top padding of the parent */
  z-index: 2;
}
.BlockGrid-nav {
  background: rgba(255, 255, 255, 0.25);
  bottom: 1rem;
  /* considers the top padding of the parent */
  color: #000;
  overflow: hidden;
  position: absolute;
  text-indent: 100%;
  top: 1rem;
  /* considers the top padding of the parent */
  z-index: 2;
}
.BlockGrid-nav:focus,
.BlockGrid-nav:active,
.BlockGrid-nav:hover {
  background: rgba(255, 255, 255, 0.5);
}
.BlockGrid-nav--prev::before,
.BlockGrid-nav--next::before {
  font-size: 23px;
  left: 0;
  top: 50%;
  position: absolute;
  text-align: center;
  text-indent: 0;
}
.BlockGrid-nav--prev {
  left: 0;
}
.BlockGrid-nav--prev::before {
  content: "\2190";
}
.BlockGrid-nav--next {
  right: 0;
  top: calc(1rem + 44px);
  /* considers the top padding of the parent and close button */
}
.BlockGrid-nav--next::before {
  content: "\2192";
}
@media (min-width: 36.063em) and (max-width: 60em) {
  .u-sm-size1of2 .BlockGrid-detail {
    width: 200%;
  }
  .u-sm-size1of2:nth-child(even) .BlockGrid-detail {
    left: -100%;
  }
}
@media (min-width: 60.063em) {
  .u-md-size1of3 .BlockGrid-detail {
    width: 300%;
  }
  .u-md-size1of3:nth-child(3n+2) .BlockGrid-detail {
    left: -100%;
  }
  .u-md-size1of3:nth-child(3n+3) .BlockGrid-detail {
    left: -200%;
  }
}
.Burger,
.Burger::after,
.Burger::before {
  background: currentColor;
  height: 0.125rem;
  width: 100%;
}
.Burger {
  display: inline-block;
  margin: 0 0.335rem 0 0;
  position: relative;
  vertical-align: top;
  width: 1rem;
}
.Burger::after,
.Burger::before {
  content: '';
  position: absolute;
}
.Burger::after {
  top: 0.5rem;
}
.Burger::before {
  top: 0.25rem;
}
.Callout {
  padding: 1.2rem;
}
.Callout a:hover {
  text-decoration: underline;
}
.Callout .Container {
  padding: 0;
}
.Callout-title {
  display: block;
  font-weight: 700;
}
.Callout--primary {
  background: #009ace;
  color: #fff;
}
.Callout--primary a {
  color: #fff;
}
.Callout--small {
  padding: 0.579rem;
}
.ColorBlock {
  position: relative;
}
.ColorBlock:before {
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: -1;
}
.ColorBlock--start1of2:before {
  width: 50%;
}
.ColorBlock--startGreenGradient:before {
  background: linear-gradient(to bottom, #35ad22 0%, #3cc326 50%);
}
@media (min-width: 60.063em) {
  .md-ColorBlock {
    position: relative;
  }
  .md-ColorBlock:before {
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: -1;
  }
}
/**
 * Requires: obj.flex-embed.less
 * only if the CoverImage element does not have a height/width
 */
.CoverImage {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  margin: 0 auto;
  max-height: 660px;
  position: relative;
  width: 100%;
}
.CoverImage .FlexEmbed-content {
  background-color: rgba(0, 0, 0, 0.5);
}
.CoverImage .FlexEmbed-content a {
  color: inherit;
}
.CoverImage .FlexEmbed-content--noBg {
  background-color: transparent;
}
.EntryContent {
  font-size: 1.2rem;
  line-height: 1.728;
}
.EntryContent h1 + ul,
.EntryContent h2 + ul,
.EntryContent h3 + ul,
.EntryContent h4 + ul,
.EntryContent h5 + ul,
.EntryContent h6 + ul,
.EntryContent h1 + ol,
.EntryContent h2 + ol,
.EntryContent h3 + ol,
.EntryContent h4 + ol,
.EntryContent h5 + ol,
.EntryContent h6 + ol {
  margin-top: 1.2rem;
}
.EntryContent .MediaCluster,
.EntryContent .MediaSlide,
.EntryContent .PullQuote,
.EntryContent .EntryFigure {
  margin-bottom: 2.074em;
  margin-top: 2.074em;
}
.EntryContent .EntryFigure--center,
.EntryContent .EntryObject--center,
.EntryContent .Button--center {
  margin-left: auto;
  margin-right: auto;
  max-width: 40rem;
}
.EntryContent li {
  margin-bottom: 0.335em;
}
.EntryContent > .EntryFigure-caption {
  border-bottom: 1px solid #b3b3b3;
}
.EntryFigure-caption {
  font-size: 0.833em;
  line-height: 1.2;
  padding: 0.833rem;
}
.EntryFigure-caption p {
  margin: 0.335rem 0;
}
.EntryObject {
  margin: 2.074rem 0;
}
.EntryObject::before,
.EntryObject::after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}
.EntryObject::after {
  clear: both;
}
.EntryObject .EntryFigure {
  float: left;
  margin: 0;
  width: 100%;
}
.EntryObject .EntryFigure-caption {
  border: none;
}
.EntryQuote-text {
  font-size: 2.074em;
  font-weight: 700;
  line-height: 1.44;
}
.EntryQuote-text::before {
  content: "\201C";
}
.EntryQuote-text::after {
  content: "\201D";
}
.EntryQuote-author {
  letter-spacing: 3px;
  margin-top: 0.402rem;
  text-transform: uppercase;
}
.EntryQuote-author::before {
  content: "\2014";
}
@media (min-width: 36.063em) {
  .EntryFigure--right,
  .EntryFigure--left {
    margin-top: 0 !important;
    max-width: calc((75rem - 40rem) / 2);
    width: 40%;
  }
  .EntryFigure--right .EntryFigure-caption,
  .EntryFigure--left .EntryFigure-caption {
    padding-left: 0;
    padding-right: 0;
  }
  .EntryFigure--left {
    float: left;
    margin-right: 1.728rem;
  }
  .EntryFigure--right {
    float: right;
    margin-left: 1.728rem;
  }
  .EntryObject--2up .EntryFigure,
  .EntryObject--3up .EntryFigure,
  .EntryObject--4up .EntryFigure {
    width: 50%;
  }
}
@media (min-width: 60.063em) {
  .EntryObject--3up .EntryFigure {
    width: 33.33333%;
  }
}
.Footer {
  background-position: left 50% bottom 60%;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 50px 0;
  position: relative;
}
.Footer:before {
  background: linear-gradient(to bottom, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  content: '';
  display: block;
  height: 50%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
.Footer .Sponsors {
  position: relative;
}
@media (min-width: 36.063em) {
  .Footer {
    padding-bottom: 125px;
    padding-top: 125px;
  }
}
.FormGroup {
  margin-bottom: 1rem;
}
.Form-label {
  color: #000;
  display: block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.44;
  margin-bottom: 0;
}
.Form-label[for] {
  cursor: pointer;
}
.Form-control {
  color: #000;
  font-weight: 700;
}
.Form--inline .FormGroup {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}
.Form--inline .Form-label {
  flex: 0 0 25%;
  margin: 0 1rem 0 0;
  max-width: 100px;
  text-align: right;
}
.Form--inline .Form-control {
  flex: 1;
}
.FormGroup--addons {
  display: flex;
  align-items: initial !important;
}
.FormGroup--addons .Form-control {
  flex: 1;
}
.FormGroup--addons .Form-control:not(:first-child) {
  border-left: none;
}
.FormGroup--addons .Form-control:not(:last-child) {
  border-right: none;
}
.FormGroup-addon {
  background: #d9d9d9;
  border-bottom: 1px solid #b3b3b3;
  border-left: 1px solid #b3b3b3;
  border-top: 1px solid #b3b3b3;
  flex: none;
  line-height: 1.2;
  margin: 0;
  padding: 0.488rem;
}
.FormGroup-addon:first-of-type {
  border-left: 1px solid #b3b3b3;
}
.FormGroup-addon:last-of-type {
  border-right: 1px solid #b3b3b3;
}
.FormGroup--radio,
.FormGroup--checkbox {
  margin-bottom: 0.335em;
  position: relative;
}
.FormGroup--radio > label,
.FormGroup--checkbox > label {
  display: inline-block;
  font-size: 1rem;
  padding-left: 1.44em;
}
.FormGroup--radio > label > input[type=checkbox],
.FormGroup--checkbox > label > input[type=checkbox],
.FormGroup--radio > label > input[type=radio],
.FormGroup--checkbox > label > input[type=radio] {
  left: 0;
  position: absolute;
  top: 3px;
}
.FormGroup--lg {
  font-size: 1.2em;
}
input#hotdogs {
  display: none;
}
.GlobalNav,
.GlobalNav-children {
  list-style: none;
  margin: 0;
  padding: 0;
}
.GlobalNav::before,
.GlobalNav-children::before,
.GlobalNav::after,
.GlobalNav-children::after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}
.GlobalNav::after,
.GlobalNav-children::after {
  clear: both;
}
.GlobalNav {
  background: #808080;
}
.GlobalNav-section {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  line-height: 2.986rem;
  position: relative;
}
.GlobalNav-title {
  background: #3a3a3a;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  color: #fff;
  display: block;
  padding-left: 1.2rem;
}
.GlobalNav-children {
  padding-left: 1rem;
}
.GlobalNav-child {
  float: left;
  width: 50%;
}
.GlobalNav-action {
  color: #000;
  padding: 0 0.335rem;
}
/*
 * Uncomment if you have to include children navigation items
 * <ul class="GlobalNav">
 *   <li class="GlobalNav-section">
 *     <a class="GlobalNav-title">About</a>
 *		 
 *     <ul class="GlobalNav-children">
 *		   <li class="GlobalNav-child"><a class="GlobalNav-action" href="">Link</a></li>
 *		 </ul>
 *   </li>
 */
/*
@media @mq-sm {
	.GlobalNav-section {
		padding-left: 200px;
	}
	
	.GlobalNav-title {
		bottom: 0-;
		left: 0;
		position: absolute;
		top: 0;
		width: 200px;
	}
}
*/
/* hide GlobalNav at this minimum width so it does not remain open 
 * to larger screens
 */
@media (min-width: 45em) {
  .GlobalNav {
    display: none;
  }
}
.c-Kicker {
  color: #44d62c;
  font-weight: 700;
  text-transform: uppercase;
}
.mapboxgl-marker {
  background-color: #009ace;
  width: 0.875rem;
  height: 0.875rem;
  border-radius: 9999px;
  cursor: pointer;
  z-index: 20;
}
.mapboxgl-popup {
  z-index: 20;
  /* moves pop-up above headline overlay and markers */
}
.mapboxgl-popup-content {
  padding: 1rem !important;
  text-align: center;
  font-family: 'pt-sans', sans-serif;
}
.mapboxgl-popup-content h3 {
  font-family: 'rift', sans-serif;
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 0.25rem;
}
.mapboxgl-popup-content p {
  font-size: 0.875rem;
  margin-bottom: 0;
}
.mapboxgl-popup-content {
  background: black;
}
@media (min-width: 36.063em) {
  .mapboxgl-marker {
    width: 1rem;
    height: 1rem;
  }
}
.MediaCluster figure {
  margin: 0;
}
.MediaSlide {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  width: 100%;
}
.MediaSlide .EntryFigure-caption {
  border: none;
}
.Nav {
  background: #fff;
  font-family: 'rift', sans-serif;
  font-size: 16px;
  height: 40px;
  overflow: hidden;
}
.Nav.is-expanded {
  height: auto;
}
.Nav-inner {
  position: relative;
}
.Nav-scroll {
  overflow-x: scroll;
  white-space: nowrap;
  backface-visibility: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
}
.Nav-scroll::-webkit-scrollbar {
  display: none;
}
.NavList {
  display: inline-block;
  list-style: none;
  margin: 0 auto;
  max-width: 60rem;
  padding: 0;
}
.NavList--b .NavList-item:last-child {
  padding-right: 6em;
}
.NavList-item {
  display: inline-block;
  margin-right: -4px;
  white-space: nowrap;
}
.NavList-action {
  color: #000;
  display: block;
  font-weight: 400;
  line-height: 40px;
  padding: 0 11px;
  text-transform: uppercase;
}
.NavList-action:focus,
.NavList-action:active,
.NavList-action:hover {
  background: #009ace;
  color: #fff;
}
.NavExpand {
  position: relative;
  z-index: 999;
}
.NavToggle {
  background: #3a3a3a;
  color: #fff;
  display: block;
  line-height: 40px;
  padding: 0 10px;
  position: absolute;
  right: 0;
  top: 0;
  width: 6em;
}
.NavToggle::before {
  background: rgba(0, 0, 0, 0.3);
  bottom: 0;
  content: '';
  left: -2px;
  position: absolute;
  top: 0;
  width: 2px;
}
.NavToggle .Burger {
  margin-top: 13px;
}
.NavToggle-label--close {
  display: none;
}
.Nav.is-expanded .NavToggle-label--close {
  display: inline-block;
}
.Nav.is-expanded .NavToggle-label--open {
  display: none;
}
@media (min-width: 45em) {
  .NavToggle {
    display: none;
  }
  .Nav-container {
    text-align: center;
  }
  .NavList--b .NavList-item:last-child {
    padding-right: 0;
  }
  .Nav-logo {
    bottom: 0;
    left: 11px;
    line-height: 40px;
    position: absolute;
    z-index: 1;
  }
}
@media (min-width: 60em) {
  .NavList {
    position: relative;
  }
  /*.NavList--a {
		right: 175px;
	}

	.NavList--b {
		left: 155px;
	}*/
}
@media (min-width: 65em) {
  .Nav {
    font-size: 23px;
  }
  /*.NavList--a {
		right: 180px;
	}

	.NavList--b {
		left: 150px;
	}*/
}
.PullQuote {
  display: block;
}
.PullQuote-text {
  font-family: 'pt-sans', sans-serif;
  font-size: 1.728em;
  font-style: italic;
  font-weight: 700;
  line-height: 1.2;
}
.PullQuote-text::before {
  content: "\201c";
  font-size: 2.074em;
  line-height: 0.833;
  position: relative;
  top: 0.194em;
}
.PullQuote-text::after {
  content: "\201d";
  font-size: 1.2em;
  line-height: 0.833;
}
.PullQuote-text + .PullQuote-caption {
  margin-top: 1em;
}
.PullQuote-caption {
  font-size: 0.833rem;
  letter-spacing: 2px;
  line-height: 1.2;
  opacity: 0.7;
  text-transform: uppercase;
}
.PullQuote-caption::before {
  content: "\2014\00a0";
}
@media (min-width: 60.063em) {
  .PullQuote--right,
  .PullQuote--left {
    max-width: calc(( 60rem  -  40rem ) / 2);
    width: 40%;
  }
  .PullQuote--left {
    float: left;
    padding-right: 1.728rem;
    text-align: right;
  }
  .PullQuote--right {
    float: right;
    padding-left: 1.728rem;
  }
}
.c-Hero--juniordev {
  background: linear-gradient(to bottom, #e0e612, #44d62c);
  color: #000;
}
.c-Hero--juniordev::before,
.c-Hero--juniordev::after {
  content: '';
  height: 20%;
  let: 0;
  position: absolute;
  width: 100%;
}
.c-Hero--juniordev::before {
  background: #e0e612;
  clip-path: polygon(100% 0, 0 0, 100% 100%);
  top: 0;
}
.c-Hero--juniordev::after {
  background: #44d62c;
  bottom: 0;
  clip-path: polygon(0 0, 100% 100%, 0 100%);
}
.c-Hero {
  overflow: hidden;
  position: relative;
}
.c-Hero-background {
  background-repeat: no-repeat;
  background-size: cover;
  bottom: -4px;
  filter: blur(4px);
  left: -4px;
  position: absolute;
  right: -4px;
  top: -4px;
}
.c-Hero-background img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.c-mission-block {
  position: relative;
}
.c-mission-block__cell {
  flex: 1 1 0%;
}
.c-mission-block__group {
  color: #fff;
  display: block;
  min-height: 16rem;
  position: relative;
}
.c-mission-block__separator {
  background: #fff;
  display: none;
  position: absolute;
  z-index: 10;
}
.c-mission-block__group-text {
  left: 50%;
  position: absolute;
  top: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  width: 90%;
}
@media (min-width: 36.063em) {
  .c-mission-block {
    display: flex;
  }
  .c-mission-block__cell:first-child .c-mission-block__group {
    clip-path: polygon(0 0, 100% 0, 88% 100%, 0 100%);
    margin-right: -6%;
  }
  .c-mission-block__cell:nth-child(2) .c-mission-block__group {
    clip-path: polygon(12% 0, 100% 0, 100% 100%, 0 100%);
    margin-left: -6%;
  }
  .c-mission-block__separator {
    display: block;
  }
}
@media (min-width: 60.063em) {
  .c-mission-block__cell:first-child .c-mission-block__group-text {
    bottom: 25%;
    left: auto;
    right: 6%;
    /* the negative margin value of the parent cell */
    text-align: right;
    top: auto;
    transform: translate(0, 0);
  }
  .c-mission-block__cell:nth-child(2) .c-mission-block__group-text {
    bottom: auto;
    left: 4%;
    /* two thirds the negative margin value of the parent cell */
    right: 0;
    text-align: left;
    top: 25%;
    transform: translate(0, 0);
  }
}
/*.c-modal {
  display: none;
}

.c-modal.is-open {
  display: block;
}*/
.c-modal__overlay {
  align-items: center;
  background: rgba(0, 0, 0, 0.65);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
}
.c-modal__container {
  background-color: #fff;
  border-radius: 4px;
  box-sizing: border-box;
  max-height: 100vh;
  max-width: 500px;
  overflow-y: auto;
  padding: 30px;
}
.c-modal__header {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.c-modal__title {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.25;
  color: #00449e;
  box-sizing: border-box;
}
.c-modal__close {
  background: transparent;
  border: 0;
}
.c-modal__header .c-modal__close:before {
  content: "\2715";
}
.c-modal__content {
  margin-top: 2rem;
  margin-bottom: 2rem;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.8);
}
/**************************\
  Demo Animation Style
\**************************/
@keyframes mmfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes mmfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes mmslideIn {
  from {
    transform: translateY(15%);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes mmslideOut {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-10%);
  }
}
.c-micromodal-slide {
  display: none;
}
.c-micromodal-slide.is-open {
  display: block;
}
.c-micromodal-slide[aria-hidden="false"] .c-modal__overlay {
  animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}
.c-micromodal-slide[aria-hidden="false"] .c-modal__container {
  animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}
.c-micromodal-slide[aria-hidden="true"] .c-modal__overlay {
  animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}
.c-micromodal-slide[aria-hidden="true"] .c-modal__container {
  animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}
.c-micromodal-slide .c-modal__container,
.c-micromodal-slide .c-modal__overlay {
  will-change: transform;
}
.c-New {
  color: #fff;
  display: inline-block;
  font-family: 'rift', sans-serif;
  font-weight: 700;
  height: 1.5em;
  line-height: 1.5;
  min-width: 1.5em;
  padding: 1px;
  position: relative;
  vertical-align: middle;
}
.c-New::before,
.c-New::after {
  background: #009ace;
  border-radius: 4px;
  content: '';
  display: block;
  left: 50%;
  padding-bottom: 100%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
.c-New::before {
  transform: translate(-50%, -50%) rotate(45deg);
  transform-origin: center center;
}
.c-New span {
  position: relative;
  z-index: 1;
}
.SectionHeader {
  margin-bottom: 1.2rem;
}
.Schedule--active {
  background-color: rgba(0, 0, 0, 0.5);
}
.Schedule--active .Schedule-event {
  font-size: 1.125rem;
  font-weight: 700;
}
.Schedule--past {
  opacity: 0.5;
}
.Schedule a {
  color: currentColor;
  text-decoration: none;
}
.slick-slider {
  position: relative;
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}
.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
}
.slick-track:before,
.slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}
.slick-arrow {
  background: none;
  border: none;
  bottom: 0;
  overflow: hidden;
  position: absolute;
  text-indent: 40px;
  top: 0;
  width: 40px;
  z-index: 1;
}
.slick-arrow:focus {
  outline: none;
}
.slick-next {
  right: 0;
}
.slick-prev {
  left: 0;
}
.Slider-item {
  max-height: 660px;
}
.Slider-item:focus {
  outline: none;
}
.Slider-item img {
  max-height: 100%;
}
.Slider--margins {
  padding-bottom: 0.833rem;
}
.Slider--margins .Slider-item {
  padding-left: 0.402rem;
  padding-right: 0.402rem;
}
.Sponsors {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
  vertical-align: middle;
}
.Sponsors img {
  max-width: 500px;
  width: 100%;
}
.Sponsors-cell {
  display: inline-block;
  margin-right: -0.279em;
  padding: 1em;
}
.c-wedge {
  clip-path: polygon(100% 0, 0 0, 100% 100%);
  height: 100%;
  left: 0;
  max-height: 300px;
  position: absolute;
  right: 0;
  top: 0;
}
.Widget {
  background: #e6e6e6;
}
.Widget .Media {
  margin: 0;
}
.Widget-section {
  margin: 1.2rem;
}
.Widget-header {
  background: #EEEF23;
  margin: 0;
  overflow: hidden;
  padding: 0.335em 0.335em 0;
  text-align: center;
}
.Widget-title {
  color: #A2C75A;
  font-size: 2.488rem;
  line-height: 0.833;
  margin: 0;
}
.Widget-body {
  padding-bottom: 1.2rem;
}
.Widget-body :last-child {
  margin-bottom: 0;
}
.Widget--raam {
  background: #009ace;
  padding: 11px;
}
.Widget--raam .Widget-heading {
  color: #fff;
  font-weight: 500;
}
/** Utilities
  *
  * Low-level structural and positional traits. Utilities can be applied directly
	* to any element within a component.
	*/
.u-bg-black {
  background: #000;
}
.u-bg-yellow {
  background: #e0e612;
}
.u-bg-green-yellow,
.u-bg-yellow-500 {
  background-color: #e0e612;
}
.u-bg-yellow-600 {
  background-color: #b3b80e;
}
.u-bg-grey {
  background: grey;
}
.u-bg-grey-darker {
  background: #333333;
}
.u-bg-gray-800 {
  background-color: #333333;
}
.u-bg-gray-900 {
  background-color: #1a1a1a;
}
.u-bg-white {
  background: #fff;
}
.hover\:u-bg-yellow-600:hover {
  background-color: #b3b80e;
}
/* Border Color
   ========================================================================== */
.u-border-yellow-500 {
  border-color: #e0e612;
}
.u-border-blue-500 {
  border-color: #009ace;
}
/* Border Style
   ========================================================================== */
/* Border Width
   ========================================================================== */
.u-border {
  border-width: 1px;
}
.u-border-0 {
  border-width: 0;
}
.u-border-2 {
  border-width: 2px;
}
.u-border-4 {
  border-width: 4px;
}
.u-border-8 {
  border-width: 8px;
}
.u-border-16 {
  border-width: 16px;
}
@media (min-width: 60.063em) {
  .md\:u-border-32 {
    border-width: 32px;
  }
}
/* Border Radius
   ========================================================================== */
.u-rounded-full {
  border-radius: 9999px;
}
.u-hidden {
  display: none;
}
.u-inline-block {
  display: inline-block;
}
@media (min-width: 36.063em) {
  .sm\:u-block {
    display: block;
  }
}
@media (min-width: 60.063em) {
  .md\:u-hidden {
    display: none;
  }
}
/* Applies to flex container
   ========================================================================== */
/**
 * Container
 */
.u-flex {
  display: flex;
}
.u-flex-1 {
  flex: 1 1 0%;
}
.u-justify-end {
  justify-content: flex-end;
}
.u-justify-between {
  justify-content: space-between;
}
.u-justify-center {
  justify-content: center;
}
.u-flex-wrap {
  flex-wrap: wrap;
}
.u-flexInline {
  display: inline-flex !important;
}
/**
 * Direction: row
 */
.u-flexRow {
  flex-direction: row !important;
}
.u-flexRowReverse {
  flex-direction: row-reverse !important;
}
/**
 * Direction: column
 */
.u-flexCol {
  flex-direction: column !important;
}
.u-flex-col {
  flex-direction: column;
}
.u-flexColReverse {
  flex-direction: column-reverse !important;
}
/**
 * Wrap
 */
.u-flexWrap {
  flex-wrap: wrap !important;
}
.u-flexNoWrap {
  flex-wrap: nowrap !important;
}
.u-flexWrapReverse {
  flex-wrap: wrap-reverse !important;
}
/**
 * Align items along the main axis of the current line of the flex container
 */
.u-flexJustifyStart {
  justify-content: flex-start !important;
}
.u-flexJustifyEnd {
  justify-content: flex-end !important;
}
.u-flexJustifyCenter {
  justify-content: center !important;
}
.u-justify-center {
  justify-content: center;
}
.u-flexJustifyBetween {
  justify-content: space-between !important;
}
.u-flexJustifyAround {
  justify-content: space-around !important;
}
/**
 * Align items in the cross axis of the current line of the flex container
 * Similar to `justify-content` but in the perpendicular direction
 */
.u-flexAlignItemsStart {
  align-items: flex-start !important;
}
.u-flexAlignItemsEnd {
  align-items: flex-end !important;
}
.u-flexAlignItemsCenter {
  align-items: center !important;
}
.u-items-center {
  align-items: center;
}
.u-flexAlignItemsStretch {
  align-items: stretch !important;
}
.u-flexAlignItemsBaseline {
  align-items: baseline !important;
}
/**
 * Aligns items within the flex container when there is extra
 * space in the cross-axis
 *
 * Has no effect when there is only one line of flex items.
 */
.u-flexAlignContentStart {
  align-content: flex-start !important;
}
.u-flexAlignContentEnd {
  align-content: flex-end !important;
}
.u-flexAlignContentCenter {
  align-content: center !important;
}
.u-flexAlignContentStretch {
  align-content: stretch !important;
}
.u-flexAlignContentBetween {
  align-content: space-between !important;
}
.u-flexAlignContentAround {
  align-content: space-around !important;
}
/* Applies to flex items
   ========================================================================== */
/**
 * Override default alignment of single item when specified by `align-items`
 */
.u-flexAlignSelfStart {
  align-self: flex-start !important;
}
.u-flexAlignSelfEnd {
  align-self: flex-end !important;
}
.u-flexAlignSelfCenter {
  align-self: center !important;
}
.u-flexAlignSelfStretch {
  align-self: stretch !important;
}
.u-flexAlignSelfBaseline {
  align-self: baseline !important;
}
.u-flexAlignSelfAuto {
  align-self: auto !important;
}
/**
 * Change order without editing underlying HTML
 */
.u-flexOrderFirst {
  order: -1 !important;
}
.u-flexOrderLast {
  order: 1 !important;
}
.u-flexOrderNone {
  order: 0 !important;
}
/**
 * Specify the flex grow factor, which determines how much the flex item will
 * grow relative to the rest of the flex items in the flex container.
 *
 * Supports 1-5 proportions
 *
 * 1. Provide all values to avoid IE10 bug with shorthand flex
 *    - http://git.io/vllC7
 *
 *    Use `0%` to avoid bug in IE10/11 with unitless flex basis. Using this
 *    instead of `auto` as this matches what the default would be with `flex`
 *    shorthand - http://git.io/vllWx
 */
.u-flexGrow1 {
  flex: 1 1 0% !important;
  /* 1 */
}
.u-flexGrow2 {
  flex: 2 1 0% !important;
}
.u-flexGrow3 {
  flex: 3 1 0% !important;
}
.u-flexGrow4 {
  flex: 4 1 0% !important;
}
.u-flexGrow5 {
  flex: 5 1 0% !important;
}
/**
 * Specify the flex shrink factor, which determines how much the flex item will
 * shrink relative to the rest of the flex items in the flex container.
 */
.u-flexShrink0 {
  flex-shrink: 0 !important;
}
.u-flexShrink1 {
  flex-shrink: 1 !important;
}
.u-flexShrink2 {
  flex-shrink: 2 !important;
}
.u-flexShrink3 {
  flex-shrink: 3 !important;
}
.u-flexShrink4 {
  flex-shrink: 4 !important;
}
.u-flexShrink5 {
  flex-shrink: 5 !important;
}
/**
 * Aligning with `auto` margins
 * http://www.w3.org/TR/css-flexbox-1/#auto-margins
 */
.u-flexExpand {
  margin: auto !important;
}
.u-flexExpandLeft {
  margin-left: auto !important;
}
.u-flexExpandRight {
  margin-right: auto !important;
}
.u-flexExpandTop {
  margin-top: auto !important;
}
.u-flexExpandBottom {
  margin-bottom: auto !important;
}
/**
 * Basis
 */
.u-flexBasisAuto {
  flex-basis: auto !important;
}
.u-flexBasis0 {
  flex-basis: 0 !important;
}
/*
 * Shorthand
 *
 * Declares all values instead of keywords like 'initial' to work around IE10
 * https://www.w3.org/TR/css-flexbox-1/#flex-common
 *
 * 1. Fixes issue in IE 10 where flex-basis is ignored - https://git.io/vllMt
 *    This ensures it overrides flex-basis set in other utilities.
 */
/*
 * Sizes the item based on the width/height properties
 */
.u-flexInitial {
  flex: 0 1 auto !important;
  flex-basis: auto !important;
  /* 1 */
}
/*
 * Sizes the item based on the width/height properties, but makes them fully
 * flexible, so that they absorb any free space along the main axis.
 */
.u-flexAuto {
  flex: 1 1 auto !important;
  flex-basis: auto !important;
  /* 1 */
}
/*
 * Sizes the item according to the width/height properties, but makes the flex
 * item fully inflexible. Similar to initial, except that flex items are
 * not allowed to shrink, even in overflow situations.
 */
.u-flexNone {
  flex: 0 0 auto !important;
  flex-basis: auto !important;
  /* 1 */
}
@media (min-width: 36.063em) {
  .sm\:u-flex {
    display: flex;
  }
}
@media (min-width: 60.063em) {
  .md\:u-flex {
    display: flex;
  }
}
.u-float-left {
  float: left;
}
.u-float-right {
  float: right;
}
.u-float-none {
  float: none;
}
.u-clearfix::after {
  content: "";
  display: table;
  clear: both;
}
@media (min-width: 60.063em) {
  .md\:u-float-left {
    float: left;
  }
  .md\:u-float-right {
    float: right;
  }
  .md\:u-float-none {
    float: none;
  }
}
.u-giga {
  font-size: 3.583rem;
}
.u-mega {
  font-size: 2.986rem;
}
h1,
.u-alpha {
  font-size: 2.074rem;
}
h2,
.u-beta {
  font-size: 1.728rem;
}
h3,
.u-gamma {
  font-size: 1.44rem;
}
h4,
h5,
.u-delta,
.u-epsilon {
  font-size: 1.2rem;
}
h5,
.u-zeta {
  font-size: 1rem;
}
.u-deci {
  font-size: 0.833rem;
}
.u-centi {
  font-size: 0.694rem;
}
.u-milli {
  font-size: 0.579rem;
}
@media (min-width: 60.063em) {
  .u-giga {
    font-size: 4.3rem;
  }
  .u-mega {
    font-size: 3.583rem;
  }
  h1,
  .u-alpha {
    font-size: 2.488rem;
  }
  h2,
  .u-beta {
    font-size: 2.074rem;
  }
  h3,
  .u-gamma {
    font-size: 1.728rem;
  }
  h4,
  .u-delta {
    font-size: 1.44rem;
  }
  h6,
  .u-zeta {
    font-size: 1rem;
  }
}
.u-float-right {
  float: right;
}
.u-float-left {
  float: left;
}
.u-clearfix::after {
  content: "";
  display: table;
  clear: both;
}
@media (min-width: 36.063em) {
  .sm\:u-float-right {
    float: right;
  }
}
@media (min-width: 60.063em) {
  .md\:u-float-right {
    float: right;
  }
}
.u-object-cover {
  object-fit: cover;
}
.u-opacity-25 {
  opacity: 0.25;
}
.u-opacity-50 {
  opacity: 0.5;
}
.u-opacity-75 {
  opacity: 0.75;
}
.u-opacity-80 {
  opacity: 0.8;
}
.u-opacity-90 {
  opacity: 0.9;
}
.u-overflow-hidden {
  overflow: hidden;
}
.u-relative {
  position: relative;
}
.u-absolute {
  position: absolute;
}
.u-pin-t {
  top: 0;
}
.u-top-0 {
  top: 0;
}
.u-pin-r {
  right: 0;
}
.u-right-0 {
  right: 0;
}
.u-pin-b {
  bottom: 0;
}
.u-bottom-0 {
  bottom: 0;
}
.u-pin-l {
  left: 0;
}
.u-left-0 {
  left: 0;
}
.u-top-25 {
  top: 25%;
}
.u-pull-5 {
  left: -1em;
}
.u-z-0 {
  z-index: 0;
}
.u-z-10 {
  z-index: 10;
}
.u-shadow {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
.u-shadow-lg {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.u-blockCenter {
  float: none;
  margin-left: auto;
  margin-right: auto;
}
.u-w-6 {
  width: 1.5rem;
}
.u-w-32 {
  width: 8rem;
}
.u-w-64 {
  width: 16rem;
}
.u-w-full {
  width: 100%;
}
/* max width */
.u-max-w-xs {
  max-width: 20rem;
}
.u-max-w-sm {
  max-width: 24rem;
}
.u-max-w-md {
  max-width: 28rem;
}
.u-max-w-lg {
  max-width: 32rem;
}
.u-max-w-xl {
  max-width: 36rem;
}
.u-max-w-2xl {
  max-width: 42rem;
}
.u-max-w-3xl {
  max-width: 48rem;
}
.u-max-w-4xl {
  max-width: 56rem;
}
.u-max-w-5xl {
  max-width: 64rem;
}
.u-max-w-6xl {
  max-width: 72rem;
}
.u-max-w-7xl {
  max-width: 80rem;
}
.u-max-w-none {
  max-width: none;
}
/* height */
.u-h-64 {
  height: 16rem;
}
.u-h-full {
  height: 100%;
}
@media (min-width: 36.063em) {
  .sm\:u-max-w-md {
    max-width: 28rem;
  }
  .sm\:u-max-w-lg {
    max-width: 32rem;
  }
}
@media (min-width: 60.063em) {
  .md\:u-max-w-xs {
    max-width: 20rem;
  }
}
/**
 * Sizing utilities
 */
/* Intrinsic widths
   ========================================================================== */
/**
 * Make an element shrink wrap its content.
 */
.u-sizeFit,
.u-sizeFitAlt {
  display: block !important;
  float: left !important;
  width: auto !important;
}
.u-sizeFitAlt {
  float: right !important;
}
/**
 * Make an element fill the remaining space.
 * N.B. This will hide overflow.
 */
.u-sizeFill {
  display: block !important;
  overflow: hidden !important;
  width: auto !important;
}
/**
 * An alternative method to make an element fill the remaining space.
 * N.B. Do not use if child elements might be wider than the remaining space.
 * In Chrome, Safari, and Firefox it results in undesired layout.
 */
.u-sizeFillAlt {
  display: table-cell !important;
  max-width: 100% !important;
  width: 10000px !important;
}
/**
 * Make an element the width of its parent.
 */
.u-sizeFull {
  box-sizing: border-box !important;
  flex: 0 0 100% !important;
  display: block !important;
  width: 100% !important;
}
/* Proportional widths
   ========================================================================== */
/**
 * Specify the proportional width of an object.
 * Intentional redundancy build into each set of unit classes.
 * Supports: 2, 3, 4, 5, 6, 8, 10, 12 part
 */
.u-size1of12 {
  flex: 0 0 calc(100% * 1 / 12) !important;
  width: calc(100% * 1 / 12) !important;
}
.u-size1of10 {
  flex: 0 0 10% !important;
  width: 10% !important;
}
.u-size1of8 {
  flex: 0 0 12.5% !important;
  width: 12.5% !important;
}
.u-size1of6,
.u-size2of12 {
  flex: 0 0 calc(100% * 1 / 6) !important;
  width: calc(100% * 1 / 6) !important;
}
.u-size1of5,
.u-size2of10 {
  flex: 0 0 20% !important;
  width: 20% !important;
}
.u-size1of4,
.u-size2of8,
.u-size3of12 {
  flex: 0 0 25% !important;
  width: 25% !important;
}
.u-size3of10 {
  flex: 0 0 30% !important;
  width: 30% !important;
}
.u-size1of3,
.u-size2of6,
.u-size4of12 {
  flex: 0 0 calc(100% * 1 / 3) !important;
  width: calc(100% * 1 / 3) !important;
}
.u-size3of8 {
  flex: 0 0 37.5% !important;
  width: 37.5% !important;
}
.u-size2of5,
.u-size4of10 {
  flex: 0 0 40% !important;
  width: 40% !important;
}
.u-size5of12 {
  flex: 0 0 calc(100% * 5 / 12) !important;
  width: calc(100% * 5 / 12) !important;
}
.u-size1of2,
.u-size2of4,
.u-size3of6,
.u-size4of8,
.u-size5of10,
.u-size6of12 {
  flex: 0 0 50% !important;
  width: 50% !important;
}
.u-size7of12 {
  flex: 0 0 calc(100% * 7 / 12) !important;
  width: calc(100% * 7 / 12) !important;
}
.u-size3of5,
.u-size6of10 {
  flex: 0 0 60% !important;
  width: 60% !important;
}
.u-size5of8 {
  flex: 0 0 62.5% !important;
  width: 62.5% !important;
}
.u-size2of3,
.u-size4of6,
.u-size8of12 {
  flex: 0 0 calc(100% * 2 / 3) !important;
  width: calc(100% * 2 / 3) !important;
}
.u-size7of10 {
  flex: 0 0 70% !important;
  width: 70% !important;
}
.u-size3of4,
.u-size6of8,
.u-size9of12 {
  flex: 0 0 75% !important;
  width: 75% !important;
}
.u-size4of5,
.u-size8of10 {
  flex: 0 0 80% !important;
  width: 80% !important;
}
.u-size5of6,
.u-size10of12 {
  flex: 0 0 calc(100% * 5 / 6) !important;
  width: calc(100% * 5 / 6) !important;
}
.u-size7of8 {
  flex: 0 0 87.5% !important;
  width: 87.5% !important;
}
.u-size9of10 {
  flex: 0 0 90% !important;
  width: 90% !important;
}
.u-size11of12 {
  flex: 0 0 calc(100% * 11 / 12) !important;
  width: calc(100% * 11 / 12) !important;
}
/* Height
   ========================================================================== */
.u-h-300px {
  height: 300px;
}
/**
 * Size: breakpoint 1 (small)
 */
@media (min-width: 36.063em) {
  .sm\:u-w-1\/3 {
    width: 33.333333%;
  }
  .sm\:u-w-1\/2 {
    width: 50%;
  }
  .sm\:u-w-2\/3 {
    width: 66.666666%;
  }
  .sm\:u-h-96 {
    height: 24rem;
  }
  /**
   * Make an element shrink wrap its content.
   */
  .u-sm-sizeFit,
  .u-sm-sizeFitAlt {
    display: block !important;
    float: left !important;
    width: auto !important;
  }
  .u-sm-sizeFitAlt {
    float: right !important;
  }
  /**
   * Make an element fill the remaining space.
   * N.B. This will hide overflow.
   */
  .u-sm-sizeFill {
    display: block !important;
    overflow: hidden !important;
    width: auto !important;
  }
  /**
   * An alternative method to make an element fill the remaining space.
   * N.B. Do not use if child elements might be wider than the remaining space.
   * In Chrome, Safari, and Firefox it results in undesired layout.
   */
  .u-sm-sizeFillAlt {
    display: table-cell !important;
    max-width: 100% !important;
    width: 10000px !important;
  }
  /**
   * Make an element the width of its parent.
   */
  .u-sm-sizeFull {
    box-sizing: border-box !important;
    display: block !important;
    flex: 0 0 100% !important;
    width: 100% !important;
  }
  /* Proportional widths: breakpoint 1 (small)
     ========================================================================== */
  /**
   * Specify the proportional width of an object.
   * Intentional redundancy build into each set of unit classes.
   * Supports: 2, 3, 4, 5, 6, 8, 10, 12 part
   */
  .u-sm-size1of12 {
    flex: 0 0 calc(100% * 1 / 12) !important;
    width: calc(100% * 1 / 12) !important;
  }
  .u-sm-size1of10 {
    flex: 0 0 10% !important;
    width: 10% !important;
  }
  .u-sm-size1of8 {
    flex: 0 0 12.5% !important;
    width: 12.5% !important;
  }
  .u-sm-size1of6,
  .u-sm-size2of12 {
    flex: 0 0 calc(100% * 1 / 6) !important;
    width: calc(100% * 1 / 6) !important;
  }
  .u-sm-size1of5,
  .u-sm-size2of10 {
    flex: 0 0 20% !important;
    width: 20% !important;
  }
  .u-sm-size1of4,
  .u-sm-size2of8,
  .u-sm-size3of12 {
    flex: 0 0 25% !important;
    width: 25% !important;
  }
  .u-sm-size3of10 {
    flex: 0 0 30% !important;
    width: 30% !important;
  }
  .u-sm-size1of3,
  .u-sm-size2of6,
  .u-sm-size4of12 {
    flex: 0 0 calc(100% * 1 / 3) !important;
    width: calc(100% * 1 / 3) !important;
  }
  .u-sm-size3of8 {
    flex: 0 0 37.5% !important;
    width: 37.5% !important;
  }
  .u-sm-size2of5,
  .u-sm-size4of10 {
    flex: 0 0 40% !important;
    width: 40% !important;
  }
  .u-sm-size5of12 {
    flex: 0 0 calc(100% * 5 / 12) !important;
    width: calc(100% * 5 / 12) !important;
  }
  .u-sm-size1of2,
  .u-sm-size2of4,
  .u-sm-size3of6,
  .u-sm-size4of8,
  .u-sm-size5of10,
  .u-sm-size6of12 {
    flex: 0 0 50% !important;
    width: 50% !important;
  }
  .u-sm-size7of12 {
    flex: 0 0 calc(100% * 7 / 12) !important;
    width: calc(100% * 7 / 12) !important;
  }
  .u-sm-size3of5,
  .u-sm-size6of10 {
    flex: 0 0 60% !important;
    width: 60% !important;
  }
  .u-sm-size5of8 {
    flex: 0 0 62.5% !important;
    width: 62.5% !important;
  }
  .u-sm-size2of3,
  .u-sm-size4of6,
  .u-sm-size8of12 {
    flex: 0 0 calc(100% * 2 / 3) !important;
    width: calc(100% * 2 / 3) !important;
  }
  .u-sm-size7of10 {
    flex: 0 0 70% !important;
    width: 70% !important;
  }
  .u-sm-size3of4,
  .u-sm-size6of8,
  .u-sm-size9of12 {
    flex: 0 0 75% !important;
    width: 75% !important;
  }
  .u-sm-size4of5,
  .u-sm-size8of10 {
    flex: 0 0 80% !important;
    width: 80% !important;
  }
  .u-sm-size5of6,
  .u-sm-size10of12 {
    flex: 0 0 calc(100% * 5 / 6) !important;
    width: calc(100% * 5 / 6) !important;
  }
  .u-sm-size7of8 {
    flex: 0 0 87.5% !important;
    width: 87.5% !important;
  }
  .u-sm-size9of10 {
    flex: 0 0 90% !important;
    width: 90% !important;
  }
  .u-sm-size11of12 {
    flex: 0 0 calc(100% * 11 / 12) !important;
    width: calc(100% * 11 / 12) !important;
  }
}
@media (min-width: 60.063em) {
  .md\:u-w-1\/4 {
    width: 25%;
  }
  .md\:u-w-1\/3 {
    width: 33.333333%;
  }
  .md\:u-w-1\/2 {
    width: 50%;
  }
  .md\:u-w-2\/3 {
    width: 66.666666%;
  }
  /**
   * Make an element shrink wrap its content.
   */
  .u-md-sizeFit,
  .u-md-sizeFitAlt {
    display: block !important;
    float: left !important;
    width: auto !important;
  }
  .u-md-sizeFitAlt {
    float: right !important;
  }
  /**
   * Make an element fill the remaining space.
   * N.B. This will hide overflow.
   */
  .u-md-sizeFill {
    display: block !important;
    overflow: hidden !important;
    width: auto !important;
  }
  /**
   * An alternative method to make an element fill the remaining space.
   * N.B. Do not use if child elements might be wider than the remaining space.
   * In Chrome, Safari, and Firefox it results in undesired layout.
   */
  .u-md-sizeFillAlt {
    display: table-cell !important;
    max-width: 100% !important;
    width: 10000px !important;
  }
  /**
   * Make an element the width of its parent.
   */
  .u-md-sizeFull {
    box-sizing: border-box !important;
    display: block !important;
    flex: 0 0 100% !important;
    width: 100% !important;
  }
  /* Proportional widths: breakpoint 1 (mdall)
     ========================================================================== */
  /**
   * Specify the proportional width of an object.
   * Intentional redundancy build into each set of unit classes.
   * Supports: 2, 3, 4, 5, 6, 8, 10, 12 part
   */
  .u-md-size1of12 {
    flex: 0 0 calc(100% * 1 / 12) !important;
    width: calc(100% * 1 / 12) !important;
  }
  .u-md-size1of10 {
    flex: 0 0 10% !important;
    width: 10% !important;
  }
  .u-md-size1of8 {
    flex: 0 0 12.5% !important;
    width: 12.5% !important;
  }
  .u-md-size1of6,
  .u-md-size2of12 {
    flex: 0 0 calc(100% * 1 / 6) !important;
    width: calc(100% * 1 / 6) !important;
  }
  .u-md-size1of5,
  .u-md-size2of10 {
    flex: 0 0 20% !important;
    width: 20% !important;
  }
  .u-md-size1of4,
  .u-md-size2of8,
  .u-md-size3of12 {
    flex: 0 0 25% !important;
    width: 25% !important;
  }
  .u-md-size3of10 {
    flex: 0 0 30% !important;
    width: 30% !important;
  }
  .u-md-size1of3,
  .u-md-size2of6,
  .u-md-size4of12 {
    flex: 0 0 calc(100% * 1 / 3) !important;
    width: calc(100% * 1 / 3) !important;
  }
  .u-md-size3of8 {
    flex: 0 0 37.5% !important;
    width: 37.5% !important;
  }
  .u-md-size2of5,
  .u-md-size4of10 {
    flex: 0 0 40% !important;
    width: 40% !important;
  }
  .u-md-size5of12 {
    flex: 0 0 calc(100% * 5 / 12) !important;
    width: calc(100% * 5 / 12) !important;
  }
  .u-md-size1of2,
  .u-md-size2of4,
  .u-md-size3of6,
  .u-md-size4of8,
  .u-md-size5of10,
  .u-md-size6of12 {
    flex: 0 0 50% !important;
    width: 50% !important;
  }
  .u-md-size7of12 {
    flex: 0 0 calc(100% * 7 / 12) !important;
    width: calc(100% * 7 / 12) !important;
  }
  .u-md-size3of5,
  .u-md-size6of10 {
    flex: 0 0 60% !important;
    width: 60% !important;
  }
  .u-md-size5of8 {
    flex: 0 0 62.5% !important;
    width: 62.5% !important;
  }
  .u-md-size2of3,
  .u-md-size4of6,
  .u-md-size8of12 {
    flex: 0 0 calc(100% * 2 / 3) !important;
    width: calc(100% * 2 / 3) !important;
  }
  .u-md-size7of10 {
    flex: 0 0 70% !important;
    width: 70% !important;
  }
  .u-md-size3of4,
  .u-md-size6of8,
  .u-md-size9of12 {
    flex: 0 0 75% !important;
    width: 75% !important;
  }
  .u-md-size4of5,
  .u-md-size8of10 {
    flex: 0 0 80% !important;
    width: 80% !important;
  }
  .u-md-size5of6,
  .u-md-size10of12 {
    flex: 0 0 calc(100% * 5 / 6) !important;
    width: calc(100% * 5 / 6) !important;
  }
  .u-md-size7of8 {
    flex: 0 0 87.5% !important;
    width: 87.5% !important;
  }
  .u-md-size9of10 {
    flex: 0 0 90% !important;
    width: 90% !important;
  }
  .u-md-size11of12 {
    flex: 0 0 calc(100% * 11 / 12) !important;
    width: calc(100% * 11 / 12) !important;
  }
}
/**
 * Size: breakpoint 3 (large)
 */
@media (min-width: 85.063em) {
  /**
   * Make an element shrink wrap its content.
   */
  .u-lg-sizeFit,
  .u-lg-sizeFitAlt {
    display: block !important;
    float: left !important;
    width: auto !important;
  }
  .u-lg-sizeFitAlt {
    float: right !important;
  }
  /**
   * Make an element fill the remaining space.
   * N.B. This will hide overflow.
   */
  .u-lg-sizeFill {
    display: block !important;
    overflow: hidden !important;
    width: auto !important;
  }
  /**
   * An alternative method to make an element fill the remaining space.
   * N.B. Do not use if child elements might be wider than the remaining space.
   * In Chrome, Safari, and Firefox it results in undesired layout.
   */
  .u-lg-sizeFillAlt {
    display: table-cell !important;
    max-width: 100% !important;
    width: 10000px !important;
  }
  /**
   * Make an element the width of its parent.
   */
  .u-lg-sizeFull {
    box-sizing: border-box !important;
    display: block !important;
    flex: 0 0 100% !important;
    width: 100% !important;
  }
  /* Proportional widths: breakpoint 3 (large)
     ========================================================================== */
  /**
   * Specify the proportional width of an object.
   * Intentional redundancy build into each set of unit classes.
   * Supports: 2, 3, 4, 5, 6, 8, 10, 12 part
   */
  .u-lg-size1of12 {
    width: calc(100% * 1 / 12) !important;
  }
  .u-lg-size1of10 {
    width: 10% !important;
  }
  .u-lg-size1of8 {
    width: 12.5% !important;
  }
  .u-lg-size1of6,
  .u-lg-size2of12 {
    width: calc(100% * 1 / 6) !important;
  }
  .u-lg-size1of5,
  .u-lg-size2of10 {
    flex: 0 0 20% !important;
    width: 20% !important;
  }
  .u-lg-size1of4,
  .u-lg-size2of8,
  .u-lg-size3of12 {
    width: 25% !important;
  }
  .u-lg-size3of10 {
    width: 30% !important;
  }
  .u-lg-size1of3,
  .u-lg-size2of6,
  .u-lg-size4of12 {
    width: calc(100% * 1 / 3) !important;
    flex: 0 0 calc(100% * 1 / 3) !important;
  }
  .u-lg-size3of8 {
    flex: 0 0 37.5% !important;
    width: 37.5% !important;
  }
  .u-lg-size2of5,
  .u-lg-size4of10 {
    width: 40% !important;
  }
  .u-lg-size5of12 {
    width: calc(100% * 5 / 12) !important;
  }
  .u-lg-size1of2,
  .u-lg-size2of4,
  .u-lg-size3of6,
  .u-lg-size4of8,
  .u-lg-size5of10,
  .u-lg-size6of12 {
    width: 50% !important;
  }
  .u-lg-size7of12 {
    width: calc(100% * 7 / 12) !important;
  }
  .u-lg-size3of5,
  .u-lg-size6of10 {
    width: 60% !important;
  }
  .u-lg-size5of8 {
    flex: 0 0 62.5% !important;
    width: 62.5% !important;
  }
  .u-lg-size2of3,
  .u-lg-size4of6,
  .u-lg-size8of12 {
    width: calc(100% * 2 / 3) !important;
    flex: 0 0 calc(100% * 2 / 3) !important;
  }
  .u-lg-size7of10 {
    width: 70% !important;
  }
  .u-lg-size3of4,
  .u-lg-size6of8,
  .u-lg-size9of12 {
    width: 75% !important;
  }
  .u-lg-size4of5,
  .u-lg-size8of10 {
    width: 80% !important;
  }
  .u-lg-size5of6,
  .u-lg-size10of12 {
    width: calc(100% * 5 / 6) !important;
  }
  .u-lg-size7of8 {
    width: 87.5% !important;
  }
  .u-lg-size9of10 {
    width: 90% !important;
  }
  .u-lg-size11of12 {
    width: calc(100% * 11 / 12) !important;
  }
}
.u-marginBn,
.u-marginVn {
  margin-bottom: 0 !important;
}
.u-marginBs {
  margin-bottom: 0.694rem !important;
}
.u-paddingTn {
  padding-top: 0 !important;
}
.u-p-0 {
  padding: 0;
}
.u-p-1 {
  padding: 0.25rem;
}
.u-p-2 {
  padding: 0.5rem;
}
.u-p-3 {
  padding: 0.75rem;
}
.u-p-4 {
  padding: 1rem;
}
.u-p-5 {
  padding: 1.25rem;
}
.u-p-6 {
  padding: 1.5rem;
}
.u-p-8 {
  padding: 2rem;
}
.u-p-10 {
  padding: 2.5rem;
}
.u-p-12 {
  padding: 3rem;
}
.u-p-16 {
  padding: 4rem;
}
.u-p-20 {
  padding: 5rem;
}
.u-p-24 {
  padding: 6rem;
}
.u-p-32 {
  padding: 8rem;
}
/* padding vertical */
.u-py-2 {
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}
.u-py-3 {
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;
}
.u-py-4 {
  padding-bottom: 1rem;
  padding-top: 1rem;
}
.u-py-8 {
  padding-bottom: 2rem;
  padding-top: 2rem;
}
.u-py-16 {
  padding-bottom: 4rem;
  padding-top: 4rem;
}
/* padding horizontal */
.u-px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.u-px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.u-px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}
/* padding top */
.u-pt-8 {
  padding-top: 2rem;
}
/* padding right */
.u-pr-1 {
  padding-right: 0.25rem;
}
/* padding bottom */
.u-pb-8 {
  padding-bottom: 2rem;
}
/* Margin
   ========================================================================== */
/* margin all */
.u-m-0 {
  margin: 0;
}
.u-m-1 {
  margin: 0.279rem;
}
.u-m-2 {
  margin: 0.402rem;
}
.u-m-3 {
  margin: 0.579rem;
}
.u-m-4 {
  margin: 0.833rem;
}
.u-m-5 {
  margin: 1rem;
}
.u-m-6 {
  margin: 1.2rem;
}
.u-m-7 {
  margin: 1.728rem;
}
.u-m-8 {
  margin: 2.488rem;
}
.u-m-9 {
  margin: 3.583rem;
}
.u-m-10 {
  margin: 5.16rem;
}
/* margin top */
.u-mt-4 {
  margin-top: 1rem;
}
.u-mt-8 {
  margin-top: 2rem;
}
.u-mt-auto {
  margin-top: auto;
}
/* margin bottom */
.u-mb-0 {
  margin-bottom: 0;
}
.u-mb-2 {
  margin-bottom: 0.5rem;
}
.u-mb-4 {
  margin-bottom: 1rem;
}
.u-mb-6 {
  margin-bottom: 1.5rem;
}
.u-mb-8 {
  margin-bottom: 2rem;
}
.u-mb-12 {
  margin-bottom: 3rem;
}
/* margin right */
.u-mr-2 {
  margin-right: 0.5rem;
}
/* margin horizontal */
.u-mx-auto {
  margin-left: auto;
  margin-right: auto;
}
/* margin vertical */
.u-my-2 {
  margin-bottom: 0.402rem;
  margin-top: 0.402rem;
}
.u-my-6 {
  margin-bottom: 1.2rem;
  margin-top: 1.2rem;
}
.u-my-8 {
  margin-bottom: 2rem;
  margin-top: 2rem;
}
/* Negative Margin
   ========================================================================== */
/* negative horizontal */
.u--mx-4 {
  margin-left: -1rem;
  margin-right: -1rem;
}
/* negative margin top */
.u--mt-4 {
  margin-top: -1rem;
}
/* negative margin right */
.u--mr-1 {
  margin-right: -0.25rem;
}
.u--mr-2 {
  margin-right: -0.5rem;
}
/* negative margin left */
.u--ml-1 {
  margin-left: -0.25rem;
}
.u--ml-2 {
  margin-left: -0.5rem;
}
@media (min-width: 36.063em) {
  .sm\:u-p-8 {
    padding: 2rem;
  }
  .u-sm\:hover\:mb-2:hover {
    margin-bottom: 0.5rem;
  }
  .sm\:u-py-12 {
    padding-bottom: 3rem;
    padding-top: 3rem;
  }
  .sm\:u-mb-0 {
    margin-bottom: 0;
  }
  .sm\:u--mt-8 {
    margin-top: -2rem;
  }
  .sm\:u--ml-4 {
    margin-left: -1rem;
  }
}
@media (min-width: 60.063em) {
  .md\:u-p-8 {
    padding: 2rem;
  }
  .md\:u-p-12 {
    padding: 3rem;
  }
  .md\:u-p-16 {
    padding: 4rem;
  }
  .md\:u-py-16 {
    padding-bottom: 4rem;
    padding-top: 4rem;
  }
  .md\:u-mt-8 {
    margin-top: 2rem;
  }
}
.u-textCenter {
  text-align: center;
}
.u-text-center {
  text-align: center;
}
.u-textLeft {
  text-align: left;
}
.u-textRight,
.u-text-right {
  text-align: right;
}
@media (min-width: 36.063em) {
  .u-sm-textCenter {
    text-align: center;
  }
  .u-sm-textLeft {
    text-align: left;
  }
  .u-sm-textRight {
    text-align: right;
  }
}
@media (min-width: 36.063em) {
  .u-md-textCenter {
    text-align: center;
  }
  .u-md-textLeft {
    text-align: left;
  }
  .u-md-textRight {
    text-align: right;
  }
}
.u-text-white {
  color: #fff;
}
.u-text-current {
  color: currentColor;
}
.u-fill-current {
  fill: currentColor;
}
/* Font Size
   ========================================================================== */
.u-text-xs {
  font-size: 0.75rem;
}
.u-text-sm {
  font-size: 0.875rem;
}
.u-text-base {
  font-size: 1rem;
}
.u-text-lg {
  font-size: 1.125rem;
}
.u-text-xl {
  font-size: 1.25rem;
}
.u-text-2xl {
  font-size: 1.5rem;
}
.u-text-3xl {
  font-size: 1.875em;
}
.u-text-4xl {
  font-size: 2.25em;
}
.u-text-5xl {
  font-size: 3rem;
}
.u-text-6xl {
  font-size: 3.75rem;
}
.u-text-7xl {
  font-size: 4.5rem;
}
.u-text-8xl {
  font-size: 6rem;
}
.u-text-9xl {
  font-size: 8rem;
}
/* Font Style
   ========================================================================== */
.u-italic {
  font-style: italic;
}
/* Font Weight
   ========================================================================== */
.u-font-bold {
  font-weight: 700;
}
/* Line Height
   ========================================================================== */
.u-leading-none {
  line-height: 1;
}
.u-leading-normal {
  line-height: 1.5;
}
/* Text Color
   ========================================================================== */
.u-text-gray-100 {
  color: #e6e6e6;
}
.u-text-gray-200 {
  color: #cccccc;
}
.u-text-gray-300 {
  color: #b3b3b3;
}
.u-text-gray-500 {
  color: #808080;
}
.u-text-gray-600 {
  color: #666666;
}
.u-text-gray-700 {
  color: #4d4d4d;
}
.u-text-black {
  color: #000;
}
.u-text-green,
.u-text-green-500 {
  color: #44d62c;
}
.u-text-green-700 {
  color: #29801a;
}
.u-text-green-800 {
  color: #1b5612;
}
.u-text-blue-400 {
  color: #009ace;
}
.u-text-yellow-500 {
  color: #e0e612;
}
/* Text Transform
   ========================================================================== */
.u-uppercase {
  text-transform: uppercase;
}
/* Text Transform
   ========================================================================== */
.u-text-shadow-xl {
  text-shadow: 0 20px 25px rgba(0, 0, 0, 0.1), 0 10px 10px rgba(0, 0, 0, 0.04);
}
@media (min-width: 36.063em) {
  .sm\:u-text-2xl {
    font-size: 1.5rem;
  }
  .sm\:u-text-3xl {
    font-size: 3.583em;
  }
  .sm\:u-text-5xl {
    font-size: 3rem;
  }
  .sm\:u-text-6xl {
    font-size: 3.75rem;
  }
  .sm\:u-text-8xl {
    font-size: 6rem;
  }
  .sm\:u-text-9xl {
    font-size: 8rem;
  }
}
@media (min-width: 60.063em) {
  .md\:u-text-lg {
    font-size: 1.125rem;
  }
  .md\:u-text-4xl {
    font-size: 2.25em;
  }
  .md\:u-text-6xl {
    font-size: 4rem;
  }
  .md\:u-text-7xl {
    font-size: 5rem;
  }
}
@media (min-width: 85.063em) {
  .lg\:u-text-6xl {
    font-size: 4rem;
  }
  .lg\:u-text-7xl {
    font-size: 5rem;
  }
}
/* Skew
   ========================================================================== */
.u-skew-x-12 {
  transform: skewX(12deg);
}
.u--skew-x-12 {
  transform: skewX(-12deg);
}
.u-transition {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
}
.u-duration-75 {
  transition-duration: 75ms;
}
.u-duration-500 {
  transition-duration: 500ms;
}
