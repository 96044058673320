.Media {
	display: block;
	margin-bottom: @base-spacing-unit;
	
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-bottom: 0.335rem;
	}
	
	> :first-child {
		margin-bottom: .694rem;
	}
}

.MediaFigure--block {
	display: flex;
	justify-content: center;
	height: 150px;
}

.MediaFigure--16by9 {
	&::before {
		padding-top: 56.25%;
	}
}

.MediaFigure--sign {
	border: 2px solid;
	margin-left: auto;
	margin-right: auto;
	max-width: 350px;
	padding: 1rem;
	width: 80%;
}

.MediaBody {
	display: block;
	margin: 0 auto;
	max-width: @base-content-width;
	
	:first-child {
		margin-top: 0;
	}
	
	:last-child {
		margin-bottom: 0;
	}
	
	.MediaFigure--block {
		max-height: 200px;
	}
}

.MediaBody--full {
	max-width: none;
}

.Media--card {
	max-width: 400px;
}

.Media--stretch {
	align-items: stretch !important;
}

.Media--connected {
	.MediaFigure {
		position: relative;
		
		&::before {
			bottom: -1px;
			border: solid transparent;
			border-color: rgba(255,255,255,0);
			border-bottom-color: #fff;
			border-width: 60px;
			border-left-width: 40px;
			border-right-width: 40px;
			content: '';
			display: block;
			height: 0;
			left: 50%;
			margin-left: -40px;
			position: absolute;
			width: 0;
		}
		
		&::after {
			background-image: url('/img/abc-arrow--up.png');
			background-repeat: no-repeat;
			background-size: 40px;
			bottom: 0;
			content: '';
			display: block;
			height: 40px;
			left: 50%;
			margin-left: -20px;
			position: absolute;
			width: 40px;
		}
	}
	
	.MediaBody + .MediaFigure {
		&::before {
			bottom: auto;
			border-bottom-color: transparent;
			border-top-color: #fff;
			top: 0;
		}
		
		&::after {
			bottom: auto;
			top: 0;
			transform: rotate(180deg);
		}
	}
}

.Media--flag {
	display: flex;
	align-items: center;
	
	> :first-child {
		margin-bottom: 0;
		padding-right: 1rem;
	}
	
	.MediaFigure {
		&::before {
			bottom: auto;
			border-bottom-color: transparent;
			border-bottom-width: 50px;
			border-right-width: 80px;
			border-right-color: @base-background-color;
			border-top-width: 50px;
			left: auto;
			margin-top: -50px;
			right: 0;
			top: 50%;
		}
		
		&::after {
			bottom: auto;
			left: auto;
			margin-top: -20px;
			right: 0;
			top: 50%;
			transform: rotate(-90deg);
		}
	}
	
	.MediaBody {
		flex: 1;
		
		+ .MediaFigure {
			&::before {
				border-left-color: @base-background-color;
				border-right-color: transparent;
				left: 0;
				right: auto;
				top: 50%;
			}
			
			&::after {
			}
		}
	}
}

@media @mq-sm {
	.sm-Media--flag {
		align-items: center;
		display: flex;
		
		> :first-child {
			margin-bottom: 0;
			padding-right: 1rem;
		}
		
		.MediaFigure {
			&::before {
				bottom: auto;
				border-bottom-color: transparent;
				border-right-color: #fff;
				left: auto;
				margin-top: -60px;
				right: 0;
				top: 50%;
			}
		}
		
		.MediaBody {
			flex: 1;
			
			+ .MediaFigure {
				&::before {
					border-left-color: #fff;
					border-right-color: transparent;
					left: 0;
					right: auto;
					top: 50%;
				}
			}
		}
	}
}

@media @mq-md {
	.md-Media--flag {
		align-items: center;
		display: flex;
		
		> :first-child {
			margin-bottom: 0;
			padding-right: 1rem;
		}
		
		.MediaFigure {
			&::before {
				bottom: auto;
				border-bottom-color: transparent;
				border-bottom-width: 40px;
				border-right-width: 60px;
				border-right-color: @base-background-color;
				border-top-width: 40px;
				left: auto;
				margin-top: -40px;
				right: 0;
				top: 50%;
			}
			
			&::after {
				bottom: auto;
				left: auto;
				margin-top: -20px;
				right: 1rem;
				top: 50%;
				transform: rotate(-90deg);
			}
			
			&:first-child {
				&::before {
					right: 1rem;
				}
			}
		}
		
		.MediaBody {
			flex: 1;
			
			+ .MediaFigure {
				&::before {
					border-left-color: #fff;
					border-left-width: 60px;
					border-right-color: transparent;
					left: 0;
					margin-left: 0;
					right: auto;
					top: 50%;
				}
				
				&::after {
					left: 0;
					margin-left: 0;
					top: 50%;
					transform: rotate(90deg);
				}
			}
		}
	}
}