.Form {
}

.FormGroup {
	margin-bottom: 1rem;
}

.Form-label {
	color: #000;
	display: block;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.44;
	margin-bottom: 0;

	&[for] {
		cursor: pointer;
	}
}

.Form-control {
	color: #000;
	font-weight: 700;
}

.Form--inline {
	.FormGroup {
		align-items: center;
		display: flex;
		flex-wrap: wrap;
	}

	.Form-label {
		flex: 0 0 25%;
		margin: 0 1rem 0 0;
		max-width: 100px;
		text-align: right;
	}

	.Form-control {
		flex: 1;
	}
}

.FormGroup--addons {
	display: flex;
	align-items: initial !important;

	.Form-control {
		flex: 1;

		&:not(:first-child) {
			border-left: none;
		}

		&:not(:last-child) {
			border-right: none;
		}
	}
}

.FormGroup-addon {
	background: darken(@grey-lighter, 5%);
	border-bottom: 1px solid @grey-light;
	border-left: 1px solid @grey-light;
	border-top: 1px solid @grey-light;
	flex: none;
	line-height: 1.2;
	margin: 0;
	padding: .488rem;

	&:first-of-type {
		border-left: 1px solid @grey-light;
	}

	&:last-of-type {
		border-right: 1px solid @grey-light;
	}
}

.FormGroup--radio,
.FormGroup--checkbox {
	margin-bottom: .335em;
	position: relative;

	> label {
		display: inline-block;
		font-size: 1rem;
		padding-left: 1.44em;

		> input[type=checkbox],
		> input[type=radio] {
			left: 0;
			position: absolute;
			top: 3px;
		}
	}
}

.FormGroup--lg {
	font-size: 1.2em;
}

input#hotdogs { display: none; }
