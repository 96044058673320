.Play,
.Play.Play--sm {
	bottom: 0;
  border: 8px solid;
	color: #fff;
	cursor: pointer;
	display: block;
	left: 0;
	margin: 1.5%;
  position: absolute;
	text-align: center;
  top: 0;
  width: 97%;
	z-index: 1;

	&::before {
		border: 8px solid;
		border-radius: 100%;
		content: '';
		display: block;
		height: 72px;
		left: 50%;
		margin: -36px 0 0 -38px;
		position: absolute;
		top: 50%;
		width: 72px;
	}
}

.Play-headline {
	font-size: 2.488rem;
	padding: 1rem;
	text-shadow: 0 2px 1px rgba(0,0,0,.5);
}

.PlayButton,
.Play--sm .PlayButton {
	left: 50%;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 36px;
}

.PlayButton-arrow {

}

@media @mq-md {
	.Play {
		border-width: 12px;

		&::before {
			border-width: 12px;
			height: 144px;
			margin: -72px 0 0 -76px;
			width: 144px;
		}
	}

	.PlayButton {
		width: 72px;
	}
}
