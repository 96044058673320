.c-Hero--juniordev {
  background: linear-gradient(to bottom, @green-yellow, @green);
  color: #000;

  &::before,
  &::after {
    content: '';
    height: 20%;
    let: 0;
    position: absolute;
    width: 100%;
  }

  &::before {
    background: @green-yellow;
    clip-path: polygon(100% 0, 0 0, 100% 100%);
    top: 0;
  }

  &::after {
    background: @green;
    bottom: 0;
    clip-path: polygon(0 0, 100% 100%, 0 100%);
  }
}

.c-Hero {
  overflow: hidden;
  position: relative;
}

.c-Hero-background {
  background-repeat: no-repeat;
  background-size: cover;
  bottom: -4px;
  filter: blur(4px);
  left: -4px;
  position: absolute;
  right: -4px;
  top: -4px;

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
}
