.Widget {
	background: @grey-lighter;
	
	.Media {
		margin: 0;
	}
}

.Widget-section {
	margin: 1.2rem;
}

.Widget-header {
	background: @yellow;
	margin: 0;
	overflow: hidden;
	padding: .335em .335em 0;
	text-align: center;
}

.Widget-title {
	color: @green-light;
	font-size: 2.488rem;
	line-height: .833;
	margin: 0;
}

.Widget-body {
	padding-bottom: 1.2rem;
	
	:last-child {
		margin-bottom: 0;
	}
}

.Widget--raam {
	background: @blue;
	padding: 11px;
	
	.Widget-heading {
		color: #fff;
		font-weight: 500;
	}
}