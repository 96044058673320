table {
	width: 100%;
}

th,
td {
	text-align: left;
	vertical-align: top;
}

thead > tr > th,
thead > tr > td {
	font-family: @heading-font-family;
	vertical-align: bottom;
	text-transform: uppercase;
}

thead > tr > th,
thead > tr > td,
tbody > tr > th,
tbody > tr > td {
	padding: .5rem;
}
