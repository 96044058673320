.Callout {
	padding: @base-spacing-unit;
	
	a:hover {
		text-decoration: underline;
	}
	
	.Container {
		padding: 0;
	}
}

.Callout-title {
	display: block;
	font-weight: 700;
}

.Callout--primary {
	background: @blue;
	color: #fff;
	
	a {
		color: #fff;
	}
}

.Callout--small {
	padding: .579rem;
}