.slick-slider {
  position: relative;
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }
 
  &.dragging {
      cursor: pointer;
      cursor: hand;
  }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;

  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
	}
  
	.slick-loading & {
    visibility: hidden;
  }
}
.slick-slide {
	float: left;
	height: 100%;
	min-height: 1px;
	[dir="rtl"] & {
		float: right;
	}
	img {
		display: block;
	}
	&.slick-loading img {
		display: none;
	}

	display: none;

	&.dragging img {
		pointer-events: none;
	}

	.slick-initialized & {
		display: block;
	}

	.slick-loading & {
		visibility: hidden;
	}

	.slick-vertical & {
		display: block;
		height: auto;
		border: 1px solid transparent;
	}
}
.slick-arrow.slick-hidden {
  display: none;
}

.slick-arrow {
	background: none;
	border: none;
	bottom: 0;
	overflow: hidden;
	position: absolute;
	text-indent: 40px;
	top: 0;
	width: 40px;
	z-index: 1;
	
	&:focus {
		outline: none;
	}
}

.slick-next {
	right: 0;
}

.slick-prev {
	left: 0;
}




.Slider-item {
	max-height: 660px;
	
	&:focus {
		outline: none;
	}
	
	img {
		max-height: 100%;
	}
}

.Slider--margins {
	padding-bottom: .833rem;
	
	.Slider-item {
		padding-left: .402rem;
		padding-right: .402rem;
	}
}