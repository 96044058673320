.mapboxgl-marker {
  background-color: @blue-500;
  width: .875rem;
  height: .875rem;
  border-radius: 9999px;
  cursor: pointer;
  z-index: 20;
}

.mapboxgl-popup {
  z-index: 20; /* moves pop-up above headline overlay and markers */
}

.mapboxgl-popup-content {
  padding: 1rem !important;
  text-align: center;
  font-family: @base-font-family;

  h3 {
    font-family: @display-font-family;
    font-size: 1.25rem;
    font-weight: 700;
    margin-bottom: .25rem;
  }

  p {
    font-size: .875rem;
    margin-bottom: 0;
  }
}

.mapboxgl-popup-content {
  background: black;
}

@media @mq-sm {
  .mapboxgl-marker {
    width: 1rem;
    height: 1rem;
  }
}
