.EntryContent {
	font-size: 1.2rem;
	line-height: 1.728;

	h1,h2,h3,h4,h5,h6 {
		+ ul,
		+ ol {
			margin-top: 1.2rem;
		}
	}

	.MediaCluster,
	.MediaSlide,
	.PullQuote,
	.EntryFigure {
		margin-bottom: 2.074em;
		margin-top: 2.074em;
	}
	
	.EntryFigure--center,
	.EntryObject--center,
	.Button--center {
		margin-left: auto;
		margin-right: auto;
		max-width: @base-content-width;
	}

	li {
		margin-bottom: .335em;
	}

	> .EntryFigure-caption {
		border-bottom: 1px solid @grey-light;
	}
}

.EntryFigure-caption {
	font-size: .833em;
	line-height: 1.2;
	padding: .833rem;

	p {
		margin: 0.335rem 0;
	}
}

.EntryObject {
	margin: 2.074rem 0;
	.u-cf;

	.EntryFigure {
		float: left;
		margin: 0;
		width: 100%;
	}

	.EntryFigure-caption {
		border: none;
	}
}

.EntryQuote-text {
	font-size: 2.074em;
	font-weight: 700;
	line-height: 1.44;

	&::before {
		content: "\201C";
	}

	&::after {
		content: "\201D";
	}
}

.EntryQuote-author {
	letter-spacing: 3px;
	margin-top: .402rem;
	text-transform: uppercase;

	&::before {
		content: "\2014";
	}
}

@media @mq-sm {
	.EntryFigure--right,
	.EntryFigure--left {
		margin-top: 0 !important;
	  max-width: ~"calc((75rem - 40rem) / 2)";
	  width: 40%;

		.EntryFigure-caption {
			padding-left: 0;
			padding-right: 0;
		}
  }

	.EntryFigure--left {
		float: left;
		margin-right: 1.728rem;
	}

	.EntryFigure--right {
		float: right;
		margin-left: 1.728rem;
	}

	.EntryObject--2up,
	.EntryObject--3up,
	.EntryObject--4up {
		.EntryFigure {
			width: 50%;
		}
	}
}

@media @mq-md {
	.EntryObject--3up {
		.EntryFigure {
			width: 33.33333%;
		}
	}
}
