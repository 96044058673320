.o-prose {
  > h1,
  > h2,
  > h3,
  > h4,
  > h5,
  > h6 {
    margin-top: 1em;
    margin-bottom: 1rem;
  }
}
