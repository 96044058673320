/**
 * Flexible media embeds
 *
 * For use with media embeds – such as videos, slideshows, or even images –
 * that need to retain a specific aspect ratio but adapt to the width of their
 * containing element.
 *
 * Based on: http://alistapart.com/article/creating-intrinsic-ratios-for-video
 */

.FlexEmbed {
  display: block;
  overflow: hidden;
  position: relative;
}

/**
 * The aspect-ratio hack is applied to an empty element because it allows
 * the component to respect `max-height`. Default aspect ratio is 1:1.
 */

.FlexEmbed-ratio {
  display: block;
  padding-bottom: 100%;
  width: 100%;
}

/**
 * Modifier: 3:1 aspect ratio
 */

.FlexEmbed-ratio--3by1 {
  padding-bottom: ~"calc(100% / 3)";
}

/**
 * Modifier: 2:1 aspect ratio
 */

.FlexEmbed-ratio--2by1 {
  padding-bottom: 50%;
}

.FlexEmbed-ratio--191by100 {
  padding-bottom: 52.36%;
}

/**
 * Modifier: 16:9 aspect ratio
 */

.FlexEmbed-ratio--16by9 {
  padding-bottom: 56.25%;
}

/**
 * Modifier: 4:3 aspect ratio
 */

.FlexEmbed-ratio--4by3 {
  padding-bottom: 75%;
}

/**
 * Fit the content to the aspect ratio
 */

.FlexEmbed-content {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;

	img {
		left: 50%;
		max-height: 100%;
		position: relative;
		top: 50%;
		transform: translateX(-50%) translateY(-50%);
	}
}

@media @mq-sm {
	.sm-FlexEmbed-ratio--2by1 {
		padding-bottom: 50%;
	}

	.sm-FlexEmbed-ratio--16by9 {
		padding-bottom: 56.25%;
	}

	.sm-FlexEmbed-ratio--1by2 {
		padding-bottom: 200%;
	}
}

@media @mq-md {
	.md-FlexEmbed-ratio--2by1 {
		padding-bottom: 50%;
	}

	.md-FlexEmbed-ratio--16by9 {
		padding-bottom: 56.25%;
	}

	.md-FlexEmbed-ratio--1by2 {
		padding-bottom: 200%;
	}
}
