.c-New {
  color: #fff;
  display: inline-block;
  font-family: @display-font-family;
  font-weight: 700;
  height: 1.5em;
  line-height: 1.5;
  min-width: 1.5em;
  padding: 1px;
  position: relative;
  vertical-align: middle;

  &::before,
  &::after {
    background: @blue;
    border-radius: 4px;
    content: '';
    display: block;
    left: 50%;
    padding-bottom: 100%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }

  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
    transform-origin: center center;
  }

  span {
    position: relative;
    z-index: 1;
  }
}
