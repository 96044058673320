.Schedule--active {
	background-color: rgba(0,0,0,.5);

	.Schedule-event {
		font-size: 1.125rem;
		font-weight: 700;
	}
}

.Schedule--past {
	opacity: .5;
}

.Schedule a {
	color: currentColor;
	text-decoration: none;
}
