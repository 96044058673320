.Nav {
	background: #fff;
	font-family: @sans-serif-alt;
	font-size: 16px;
	height: 40px;
	overflow: hidden;

	&.is-expanded {
		height: auto;
	}
}

.Nav-inner {
	position: relative;
}

.Nav-scroll {
	overflow-x: scroll;
	white-space: nowrap;
	backface-visibility: hidden;
	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: none;

	&::-webkit-scrollbar {
    display: none;
  }
}

.NavList {
	display: inline-block;
	list-style: none;
	margin: 0 auto;
	max-width: @base-site-width;
	padding: 0;

	&--b {
		.NavList-item {
			&:last-child {
				padding-right: 6em;
			}
		}
	}
}

.NavList-item {
	display: inline-block;
	margin-right: -4px;
	white-space: nowrap;
}

.NavList-action {
	color: #000;
	display: block;
	font-weight: 400;
	line-height: 40px;
	padding: 0 11px;
	text-transform: uppercase;

	&:focus,
	&:active,
	&:hover {
		background: @blue-500;
		color: #fff;
	}
}

.NavExpand {
	position: relative;
	z-index: 999;
}


.NavToggle {
	background: #3a3a3a;
	color: #fff;
	display: block;
	line-height: 40px;
	padding: 0 10px;
	position: absolute;
	right: 0;
	top: 0;
	width: 6em;

	&::before {
		background: rgba(0,0,0,.3);
		bottom: 0;
		content: '';
		left: -2px;
		position: absolute;
		top: 0;
		width: 2px;
	}

	.Burger {
		margin-top: 13px;
	}
}

.NavToggle-label--close {
	display: none;

	.Nav.is-expanded & {
		display: inline-block;
	}
}

.NavToggle-label--open {
	.Nav.is-expanded & {
		display: none;
	}
}

@media (min-width: 45em) {
	.NavToggle {
		display: none;
	}

	.Nav-container {
		text-align: center;
	}

	.NavList--b .NavList-item:last-child {
		padding-right: 0;
	}

	.Nav-logo {
		bottom: 0;
		left: 11px;
		line-height: 40px;
		position: absolute;
		z-index: 1;
	}
}

@media (min-width: 60em) {
	.NavList {
		position: relative;
	}

	/*.NavList--a {
		right: 175px;
	}

	.NavList--b {
		left: 155px;
	}*/
}

@media (min-width: 65em) {
	.Nav {
		font-size: 23px;
	}

	/*.NavList--a {
		right: 180px;
	}

	.NavList--b {
		left: 150px;
	}*/
}
