.Banner {
	background: #000;
	overflow: hidden;
	position: relative;
	padding: 0;

	.Nav,
	.Callout {
		position: relative;
		z-index: 2;
	}
}

.Banner-logo {
	background: #000;
	margin: 0;
	padding: 16px 0;
	position: relative;
	text-align: center;
	z-index: 3;

	img {
		max-width: 300px;
		width: 100%;
	}
}

.Banner-heading {
	color: #fff;
	font-family: @sans-serif-alt;
	margin: 0 auto;
	max-width: @base-site-width;
	padding: 0;
	text-align: center;
	text-shadow: 0 1px 1px rgba(0,0,0,.65);
	text-transform: uppercase;
	z-index: 1;
}

.Banner-title {
	padding-bottom: 1rem;
	padding-top: 7.43rem;
}

.Banner--tall {
	height: 100vh;
	max-height: 960px;
}

@media (min-width: 60em) {
	/*.Banner-logo {
		box-shadow: 0 3px 2px -1px rgba(0,0,0,.5);
		left: 0;
		margin: 0 auto;
		padding: 16px;
		position: absolute;
		right: 0;
		top: 0;
		width: 320px;
	}*/
}
