/* Border Color
   ========================================================================== */
.u-border-yellow-500 { border-color: @yellow-500; }

.u-border-blue-500 { border-color: @blue; }

/* Border Style
   ========================================================================== */

/* Border Width
   ========================================================================== */
.u-border { border-width: 1px; }
.u-border-0 { border-width: 0; }
.u-border-2 { border-width: 2px; }
.u-border-4 { border-width: 4px; }
.u-border-8 { border-width: 8px; }
.u-border-16 { border-width: 16px; }

@media @mq-md {
  .md\:u-border-32 { border-width: 32px; }
}

/* Border Radius
   ========================================================================== */
.u-rounded-full { border-radius: 9999px; }
