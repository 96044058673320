.Layout {
	display: flex;
	flex-direction: column;
}

.Layout-cell {
	flex: 1;
	position: relative;
	
	> .Layout {
		flex: 1;
	}
}

.Layout-cell--left {
	margin-right: auto;
}

.Layout-cell--right {
	margin-left: auto;
}

.Layout-cell--middle {
	align-self: center;
}

.Layout-cell--fit {
	flex: 0 0 auto;
}

.Layout--row {
	flex-direction: row;
	flex-wrap: wrap;
	
	.Layout-rail {
		flex: 0 0 20em;
	}
}

.Layout-rail {
	.Widget:not(:first-child) {
		margin-top: 1.2rem;
	}
}

.Layout--top {
	align-items: flex-start;
}

.Layout--center {
	align-items: center;
	justify-content: center;
	
	> .Layout-cell {
		flex: none;
	}
}

.Layout--stretch {
	align-items: stretch;
}

.Layout--bottom {
	align-items: flex-end;
}

.Layout--withGutter {
	margin: -1rem -1rem 0;
	
	> .Layout-cell {
	  padding: 1rem 1rem 0;
	}
	
	.EntryFigure-caption {
		padding-left: 0;
		padding-right: 0;
	}
}

@media @mq-sm {
	
	.Layout--withGutter,
	.sm-Layout--withGutter {
		margin: -1.2rem -1.2rem 0;
		
		> .Layout-cell {
			padding: 1.2rem 1.2rem 0;
		}
		
		.EntryFigure-caption {
			padding-left: 0;
			padding-right: 0;
		}
	}
	
	.sm-Layout--noGutter {
		margin: 0;
		
		> .Layout-cell {
			padding: 0;
		}
		
		.EntryFigure-caption {
			padding-left: .833rem;
			padding-right: .833rem;
		}
	}
	
	.sm-Layout--row {
		flex-direction: row;
		flex-wrap: wrap;
		
		.Layout-rail {
			flex: 0 0 20em;
		}
	}
	
	.sm-Layout--column {
		flex-direction: column;
		flex-wrap: nowrap;
	}
	
	.sm-Layout-rail {
		flex: 0 0 20em;
	}
	
	.sm-Layout--center {
		align-items: center;
		justify-content: center;
		
		> .Layout-cell {
			flex: none;
		}
	}
}

@media @mq-md {
	.md-Layout--withGutter {
		margin: -1.2rem -1.2rem 0;
		
		> .Layout-cell {
			padding: 1.2rem 1.2rem 0;
		}
		
		.EntryFigure-caption {
			padding-left: 0;
			padding-right: 0;
		}
	}
	
	.md-Layout--noGutter {
		margin: 0;
		
		> .Layout-cell {
			padding: 0;
		}
		
		.EntryFigure-caption {
			padding-left: .833rem;
			padding-right: .833rem;
		}
	}
	
	.md-Layout--row {
		flex-direction: row;
		flex-wrap: wrap;
		
		.Layout-rail {
			flex: 0 0 20em;
		}
	}
	
	.md-Layout--column {
		flex-direction: column;
		flex-wrap: nowrap;
	}
	
	.md-Layout-rail {
		flex: 0 0 20em;
	}
	
	.md-Layout--center {
		align-items: center;
		justify-content: center;
		
		> .Layout-cell {
			flex: none;
		}
	}
}

@media @mq-lg {
	.lg-Layout--withGutter {
		margin: -1.2rem -1.2rem 0;
		
		> .Layout-cell {
			padding: 1.2rem 1.2rem 0;
		}
		
		.EntryFigure-caption {
			padding-left: 0;
			padding-right: 0;
		}
	}
	
	.lg-Layout--noGutter {
		margin: 0;
		
		> .Layout-cell {
			padding: 0;
		}
		
		.EntryFigure-caption {
			padding-left: .833rem;
			padding-right: .833rem;
		}
	}
	
	.lg-Layout--row {
		flex-direction: row;
		flex-wrap: wrap;
	}
	
	.lg-Layout--column {
		flex-direction: column;
		flex-wrap: nowrap;
	}
	
	.lg-Layout-rail {
		flex: 0 0 20em;
	}
	
	.lg-Layout--center {
		align-items: center;
		justify-content: center;
		
		> .Layout-cell {
			flex: none;
		}
	}
}