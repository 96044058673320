.Sponsors {
	list-style: none;
	margin: 0;
	padding: 0;
	text-align: center;
	vertical-align: middle;
	
	img {
		max-width: 500px;
		width: 100%;
	}
}

.Sponsors-cell {
	display: inline-block;
	margin-right: -0.279em;
	padding: 1em;
}
	