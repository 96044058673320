.BlockGrid {
	display: flex;
	flex-wrap: wrap;
	
	&:hover {
		.BlockGrid-action {
			opacity: .5;
		}
	}
}

.BlockGrid-item {
	position: relative;
	
	&:target {
		padding-bottom: ~"calc(56.25% + 2rem)";
		
		.BlockGrid-action {
			opacity: 1;
		}
	}
	
	&:hover,
	&:focus,
	&:active {
		.BlockGrid-action {
			opacity: 1;
		}
	}
}

.BlockGrid-action {
	display: block;
	position: relative;
	
	.BlockGrid-item:target & {
		&::before {
			bottom: -1px;
			border: solid transparent;
			border-color: rgba(255,255,255,0);
			border-bottom-color: #fff;
			border-width: 60px;
			border-left-width: 40px;
			border-right-width: 40px;
			content: '';
			display: block;
			height: 0;
			left: 50%;
			margin-left: -40px;
			position: absolute;
			width: 0;
			z-index: 1;
		}
		
		&::after {
			background-image: url('/img/abc-arrow--up.png');
			background-repeat: no-repeat;
			background-size: 40px;
			bottom: 0;
			content: '';
			display: block;
			height: 40px;
			left: 50%;
			margin-left: -20px;
			position: absolute;
			width: 40px;
			z-index: 1;
		}
	}
}

.BlockGrid-detail {
	background: linear-gradient(to bottom, #fff 0, @grey-lighter 100%);
	bottom: 0;
	display: none;
	padding: 1rem 0;
	left: 0;
	position: absolute;
	width: 100%;
	
	.BlockGrid-item:target & {
		display: block
	}
}

.BlockGrid-close,
.BlockGrid-nav,
.BlockGrid-nav--prev::before,
.BlockGrid-nav--next::before {
	width: 60px;
}

.BlockGrid-close {
	background: #fff;
	color: #000;
	display: block;
	font-size: 33px;
	font-weight: 700;
	height: 44px;
	line-height: 44px;
	position: absolute;
	right: 0;
	text-align: center;
	top: 1rem; /* considers the top padding of the parent */
	z-index: 2;
}

.BlockGrid-nav {
	background: rgba(255,255,255,.25);
	bottom: 1rem; /* considers the top padding of the parent */
	color: #000;
	overflow: hidden;
	position: absolute;
	text-indent: 100%;
	top: 1rem; /* considers the top padding of the parent */
	z-index: 2;
	
	&:focus,
	&:active,
	&:hover {
		background: rgba(255,255,255,.5);
	}
}

.BlockGrid-nav--prev::before,
.BlockGrid-nav--next::before {
	font-size: 23px;
	left: 0;
	top: 50%;
	position: absolute;
	text-align: center;
	text-indent: 0;
}

.BlockGrid-nav--prev {
	left: 0;
	
	&::before {
		content: "\2190";
	}
}

.BlockGrid-nav--next {
	right: 0;
	top: ~"calc(1rem + 44px)"; /* considers the top padding of the parent and close button */
	
	&::before {
		content: "\2192";
	}
}

@media @mq-sm and @mq-md-max {
	.BlockGrid-detail {
		.u-sm-size1of2 & {
			width: 200%;
		}
		
		.u-sm-size1of2:nth-child(even) & {
		  left: -100%;
		}
	}
}

@media @mq-md {
	.BlockGrid-detail {
		.u-md-size1of3 & {
			width: 300%;
		}
		
		.u-md-size1of3:nth-child(3n+2) & {
			left: -100%;
		}
		
		.u-md-size1of3:nth-child(3n+3) & {
		  left: -200%;
		}
	}
}