.u-hidden { display: none; }
.u-inline-block { display: inline-block; }

@media @mq-sm {
  .sm\:u-block { display: block; }
}

@media @mq-md {
  .md\:u-hidden { display: none; }
}
