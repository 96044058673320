.ColorBlock {
	position: relative;
	
	&:before {
		content: '';
		display: block;
		height: 100%;
		position: absolute;
		top: 0;
		z-index: -1;
	}
}

.ColorBlock--start1of2 {
	&:before {
		width: 50%;
	}
}

.ColorBlock--startGreenGradient {
	&:before {
		.GreenGradient;
	}
}
	
@media @mq-md {
	.md-ColorBlock {
		position: relative;
		
		&:before {
			content: '';
			display: block;
			height: 100%;
			position: absolute;
			top: 0;
			z-index: -1;
		}
	}
}		