.u-bg-black { background: #000; }

.u-bg-yellow { background: @yellow-500; }
.u-bg-green-yellow,
.u-bg-yellow-500 { background-color: @yellow-500; }
.u-bg-yellow-600 { background-color: @yellow-600; }

.u-bg-grey { background: grey; }
.u-bg-grey-darker { background: @grey-darker; }
.u-bg-gray-800 { background-color: @gray-800; }
.u-bg-gray-900 { background-color: @gray-900; }

.u-bg-white { background: #fff; }

.hover\:u-bg-yellow-600:hover { background-color: @yellow-600; }
