.Box {
	display: block;
	padding: 1rem;
}

.Box--sm {
	padding: .335em;
}

.Box--flush {
	padding: 0;
}

.Box--black {
	background-color: #000;
	color: @grey-lighter;
}

.Box--greenGradient {
	color: #fff;
	.GreenGradient;
}

.Box--flushH {
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.Box--card {
	border: 1px solid currentColor;
}
