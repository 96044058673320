.u-marginBn,
.u-marginVn { margin-bottom: 0 !important; }

.u-marginBs { margin-bottom: .694rem !important; }

.u-paddingTn { padding-top: 0 !important; }

.u-p-0 { padding: 0; }
.u-p-1 { padding: .25rem; }
.u-p-2 { padding: .5rem; }
.u-p-3 { padding: .75rem; }
.u-p-4 { padding: 1rem; }
.u-p-5 { padding: 1.25rem; }
.u-p-6 { padding: 1.5rem; }
.u-p-8 { padding: 2rem; }
.u-p-10 { padding: 2.5rem; }
.u-p-12 { padding: 3rem; }
.u-p-16 { padding: 4rem; }
.u-p-20 { padding: 5rem; }
.u-p-24 { padding: 6rem; }
.u-p-32 { padding: 8rem; }

/* padding vertical */
.u-py-2 { padding-bottom: .5rem; padding-top: .5rem; }
.u-py-3 { padding-bottom: .75rem; padding-top: .75rem; }
.u-py-4 { padding-bottom: 1rem; padding-top: 1rem; }
.u-py-8 { padding-bottom: 2rem; padding-top: 2rem; }
.u-py-16 { padding-bottom: 4rem; padding-top: 4rem; }

/* padding horizontal */
.u-px-4 { padding-left: 1rem; padding-right: 1rem; }
.u-px-6 { padding-left: 1.5rem; padding-right: 1.5rem; }
.u-px-8 { padding-left: 2rem; padding-right: 2rem; }

/* padding top */
.u-pt-8 { padding-top: 2rem; }
/* padding right */
.u-pr-1 { padding-right: .25rem; }
/* padding bottom */
.u-pb-8 { padding-bottom: 2rem; }

/* Margin
   ========================================================================== */

/* margin all */
.u-m-0 { margin: 0; }
.u-m-1 { margin: .279rem; }
.u-m-2 { margin: .402rem; }
.u-m-3 { margin: .579rem; }
.u-m-4 { margin: .833rem; }
.u-m-5 { margin: 1rem; }
.u-m-6 { margin: 1.2rem; }
.u-m-7 { margin: 1.728rem; }
.u-m-8 { margin: 2.488rem; }
.u-m-9 { margin: 3.583rem; }
.u-m-10 { margin: 5.16rem; }

/* margin top */
.u-mt-4 { margin-top: 1rem; }
.u-mt-8 { margin-top: 2rem; }
.u-mt-auto { margin-top: auto; }

/* margin bottom */
.u-mb-0 { margin-bottom: 0; }
.u-mb-2 { margin-bottom: .5rem; }
.u-mb-4 { margin-bottom: 1rem; }
.u-mb-6 { margin-bottom: 1.5rem; }
.u-mb-8 { margin-bottom: 2rem; }
.u-mb-12 { margin-bottom: 3rem; }

/* margin right */
.u-mr-2 { margin-right: .5rem; }

/* margin horizontal */
.u-mx-auto {
  margin-left: auto;
  margin-right: auto;
}

/* margin vertical */
.u-my-2 {
  margin-bottom: .402rem;
  margin-top: .402rem;
}
.u-my-6 {
  margin-bottom: 1.2rem;
  margin-top: 1.2rem;
}
.u-my-8 { margin-bottom: 2rem; margin-top: 2rem; }

/* Negative Margin
   ========================================================================== */
/* negative horizontal */
.u--mx-4 { margin-left: -1rem; margin-right: -1rem; }

/* negative margin top */
.u--mt-4 { margin-top: -1rem; }

/* negative margin right */
.u--mr-1 { margin-right: -.25rem; }
.u--mr-2 { margin-right: -.5rem; }

/* negative margin left */
.u--ml-1 { margin-left: -.25rem; }
.u--ml-2 { margin-left: -.5rem; }


@media @mq-sm {
  .sm\:u-p-8 { padding: 2rem; }
  .u-sm\:hover\:mb-2:hover {
    .u-mb-2;
  }
  .sm\:u-py-12 { padding-bottom: 3rem; padding-top: 3rem; }

  .sm\:u-mb-0 { margin-bottom: 0; }

  .sm\:u--mt-8 { margin-top: -2rem; }
  .sm\:u--ml-4 { margin-left: -1rem; }
}

@media @mq-md {
  .md\:u-p-8 { padding: 2rem; }
  .md\:u-p-12 { padding: 3rem; }
  .md\:u-p-16 { padding: 4rem; }
  .md\:u-py-16 { padding-bottom: 4rem; padding-top: 4rem; }
  .md\:u-mt-8 { margin-top: 2rem; }
}
