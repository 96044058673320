form {
	margin: 0 0 1.2rem 0;
}

lable {
	display: inline-block;
}

input[type="text"], input[type="password"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="month"], input[type="week"], input[type="email"], input[type="number"], input[type="search"], input[type="tel"], input[type="time"], input[type="url"], input[type="color"], textarea {
	background-color: #efefef;
	border: none;
	border-radius: 0;
	display: block;
	font-size: 1rem;
	line-height: 1.2;
	padding: .488rem;
	width: 100%;
}

input[type="radio"],
input[type="checkbox"] {
	margin-right: 4px;
	vertical-align: middle;
}

textarea {
  max-width: 100%;
}

select {
  -webkit-appearance: none !important;
	background-color: #efefef;
	background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeD0iMTJweCIgeT0iMHB4IiB3aWR0aD0iMjRweCIgaGVpZ2h0PSIzcHgiIHZpZXdCb3g9IjAgMCA2IDMiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDYgMyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBvbHlnb24gcG9pbnRzPSI1Ljk5MiwwIDIuOTkyLDMgLTAuMDA4LDAgIi8+PC9zdmc+);
  background-position: left center;
  background-repeat: no-repeat;
	border: none;
	border-radius: 0;
	height: 2.25em;
	padding: .488rem .488rem .488rem 2rem;
	width: 100%;
}