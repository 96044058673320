/*
 * Clearfix: contain floats
 *
 * For modern browsers
 * 1. The space content is one way to avoid an Opera bug when the
 *    `contenteditable` attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of elements
 *    that receive the `clearfix` class.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 */

.u-cf {
	&::before,
  &::after {
    content: " "; /* 1 */
		display: table; /* 2 */
	}

	&::after {
    clear: both;
  }
}

.GreenGradient {
	background: linear-gradient(to bottom, darken(@green-500, 10%) 0%, darken(@green-500, 5%) 50%);
}
