.PullQuote {
	display: block;
}

.PullQuote-text {
	font-family: @base-font-family;
	font-size: 1.728em;
	font-style: italic;
	font-weight: 700;
	line-height: 1.2;
	
	&::before,
	&::after {
		
	}
	
	&::before {
		content: "\201c";
		font-size: 2.074em;
		line-height: .833;
		position: relative;
		top: .194em;
	}
	
	&::after {
		content: "\201d";
		font-size: 1.2em;
		line-height: .833;
	}
	
	+ .PullQuote-caption {
		margin-top: 1em;
	}
}

.PullQuote-caption {
	font-size: 0.833rem;
	letter-spacing: 2px;
	line-height: 1.2;
	opacity: .7;
	text-transform: uppercase;
	
	&::before {
		content: "\2014\00a0";
	}
}
@media @mq-md {
	.PullQuote--right,
	.PullQuote--left {
	  max-width: ~"calc(("@base-site-width~" - "@base-content-width~") / 2)";
	  width: 40%;
  }
	
	.PullQuote--left {
		float: left;
		padding-right: 1.728rem;
		text-align: right;
	}
	
	.PullQuote--right {
		float: right;
		padding-left: 1.728rem;
	}
}

