.u-textCenter {
	text-align: center;
}
.u-text-center { text-align: center; }

.u-textLeft {
	text-align: left;
}

.u-textRight,
.u-text-right {
	text-align: right;
}

@media @mq-sm {
	.u-sm-textCenter {
		text-align: center;
	}

	.u-sm-textLeft {
		text-align: left;
	}

	.u-sm-textRight {
		text-align: right;
	}
}

@media @mq-sm {
	.u-md-textCenter {
		text-align: center;
	}

	.u-md-textLeft {
		text-align: left;
	}

	.u-md-textRight {
		text-align: right;
	}
}

.u-text-white {
	color: #fff;
}
.u-text-current { color: currentColor; }
.u-fill-current { fill: currentColor; }

/* Font Size
   ========================================================================== */
.u-text-xs { font-size: .75rem; }
.u-text-sm { font-size: .875rem;}
.u-text-base { font-size: 1rem; }
.u-text-lg { font-size: 1.125rem; }
.u-text-xl { font-size: 1.25rem; }
.u-text-2xl { font-size: 1.5rem; }
.u-text-3xl { font-size: 1.875em; }
.u-text-4xl { font-size: 2.25em; }
.u-text-5xl { font-size: 3rem; }
.u-text-6xl { font-size: 3.75rem; }
.u-text-7xl { font-size: 4.5rem; }
.u-text-8xl { font-size: 6rem; }
.u-text-9xl { font-size: 8rem; }

/* Font Style
   ========================================================================== */
.u-italic { font-style: italic; }

/* Font Weight
   ========================================================================== */
.u-font-bold { font-weight: 700; }

/* Line Height
   ========================================================================== */
.u-leading-none { line-height: 1; }
.u-leading-normal { line-height: 1.5; }

/* Text Color
   ========================================================================== */
.u-text-gray-100 { color: @gray-100; }
.u-text-gray-200 { color: @gray-200; }
.u-text-gray-300 { color: @gray-300; }
.u-text-gray-500 { color: @gray-500; }
.u-text-gray-600 { color: @gray-600; }
.u-text-gray-700 { color: @gray-700; }
.u-text-black { color: #000; }

.u-text-green,
.u-text-green-500 { color: @green-500; }
.u-text-green-700 { color: @green-700; }
.u-text-green-800 { color: @green-800; }

.u-text-blue-400 { color: @blue; }

.u-text-yellow-500 { color: @yellow-500; }

/* Text Transform
   ========================================================================== */
.u-uppercase { text-transform: uppercase; }

/* Text Transform
   ========================================================================== */
.u-text-shadow-xl { text-shadow: 0 20px 25px rgba(0, 0, 0, 0.1), 0 10px 10px rgba(0, 0, 0, 0.04); }

@media @mq-sm {
	.sm\:u-text-2xl { font-size: 1.5rem; }
	.sm\:u-text-3xl {
		font-size: 3.583em;
	}
	.sm\:u-text-5xl { font-size: 3rem; }
	.sm\:u-text-6xl { font-size: 3.75rem; }
	.sm\:u-text-8xl { font-size: 6rem; }
	.sm\:u-text-9xl { font-size: 8rem; }
}

@media @mq-md {
	.md\:u-text-lg { font-size: 1.125rem; }
	.md\:u-text-4xl {
		font-size: 2.25em;
	}

	.md\:u-text-6xl { font-size: 4rem; }
	.md\:u-text-7xl { font-size: 5rem; }
}

@media @mq-lg {
	.lg\:u-text-6xl { font-size: 4rem; }
	.lg\:u-text-7xl { font-size: 5rem; }
}
