/*.c-modal {
  display: none;
}

.c-modal.is-open {
  display: block;
}*/

.c-modal__overlay {
  align-items: center;
  background: rgba(0,0,0,0.65);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
}

.c-modal__container {
  background-color: #fff;
  border-radius: 4px;
  box-sizing: border-box;
  max-height: 100vh;
  max-width: 500px;
  overflow-y: auto;
  padding: 30px;
}

.c-modal__header {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.c-modal__title {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.25;
  color: #00449e;
  box-sizing: border-box;
}

.c-modal__close {
  background: transparent;
  border: 0;
}

.c-modal__header .c-modal__close:before { content: "\2715"; }

.c-modal__content {
  margin-top: 2rem;
  margin-bottom: 2rem;
  line-height: 1.5;
  color: rgba(0,0,0,.8);
}



/**************************\
  Demo Animation Style
\**************************/
@keyframes mmfadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
}

@keyframes mmfadeOut {
    from { opacity: 1; }
      to { opacity: 0; }
}

@keyframes mmslideIn {
  from { transform: translateY(15%); }
    to { transform: translateY(0); }
}

@keyframes mmslideOut {
    from { transform: translateY(0); }
    to { transform: translateY(-10%); }
}

.c-micromodal-slide {
  display: none;
}

.c-micromodal-slide.is-open {
  display: block;
}

.c-micromodal-slide[aria-hidden="false"] .c-modal__overlay {
  animation: mmfadeIn .3s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.c-micromodal-slide[aria-hidden="false"] .c-modal__container {
  animation: mmslideIn .3s cubic-bezier(0, 0, .2, 1);
}

.c-micromodal-slide[aria-hidden="true"] .c-modal__overlay {
  animation: mmfadeOut .3s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.c-micromodal-slide[aria-hidden="true"] .c-modal__container {
  animation: mmslideOut .3s cubic-bezier(0, 0, .2, 1);
}

.c-micromodal-slide .c-modal__container,
.c-micromodal-slide .c-modal__overlay {
  will-change: transform;
}
