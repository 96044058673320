h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: @heading-font-family;
	font-weight: 700;
	line-height: 1;
	margin: 0;
	text-transform: uppercase;
	
	a {
		color: inherit;
	}
}

