/**
 * The list-inline object simply displays a list of items in one line.
 */
.ListInline {
	margin: 0 0 1.2rem;
	padding: 0;
	list-style: none;

	> .ListInline-item {
		display: inline-block;
		line-height: 1.2;
		margin-right: 8px;
	}
}