.GlobalNav,
.GlobalNav-children {
	list-style: none;
	margin: 0;
	padding: 0;
	.u-cf;
}

.GlobalNav {
	background: @grey;
}

.GlobalNav-section  {
	box-shadow: inset 0 1px 0 fade(#fff, 10%);
	line-height: 2.986rem;
	position: relative;
}

.GlobalNav-title {
	background: #3a3a3a;
	box-shadow: inset 0 1px 0 fade(#fff, 10%);
	color: #fff;
	display: block;
	padding-left: 1.2rem;
}

.GlobalNav-children {
	padding-left: 1rem;
}

.GlobalNav-child {
	float: left;
	width: 50%;
}

.GlobalNav-action {
	color: #000;
	padding: 0 .335rem;
}

/*
 * Uncomment if you have to include children navigation items
 * <ul class="GlobalNav">
 *   <li class="GlobalNav-section">
 *     <a class="GlobalNav-title">About</a>
 *		 
 *     <ul class="GlobalNav-children">
 *		   <li class="GlobalNav-child"><a class="GlobalNav-action" href="">Link</a></li>
 *		 </ul>
 *   </li>
 */
 
/*
@media @mq-sm {
	.GlobalNav-section {
		padding-left: 200px;
	}
	
	.GlobalNav-title {
		bottom: 0-;
		left: 0;
		position: absolute;
		top: 0;
		width: 200px;
	}
}
*/

/* hide GlobalNav at this minimum width so it does not remain open 
 * to larger screens
 */
@media (min-width: 45em) {
	.GlobalNav {
		display: none;
	}
}