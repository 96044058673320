/**
 * Where `margin-bottom` is concerned,this value will be the same as the
 * base line-height. This allows us to keep a consistent vertical rhythm.
 * As per: csswizardry.com/2012/06/single-direction-margin-declarations
 */
h1, h2, h3, h4, h5, h6,
ul, ol, dl,
blockquote, p, address,
hr,
table,
fieldset, figure,
pre {
  margin-bottom: @base-spacing-unit;
	margin-top: 0;
}


/**
 * Where `margin-left` is concerned we want to try and indent certain elements
 * by a consistent amount. Define that amount once,here.
 */
ul, ol, dd {
  margin-left: 1.728rem;
	padding-left: 1.728rem;
}

@media @mq-md {
	ul, ol, dd {
		margin-left: 2.986rem;
	}
}