/* Grid container
   ========================================================================== */

/**
 * All content must be contained within child `Grid-cell` elements.
 *
 * 1. Account for browser defaults of elements that might be the root node of
 *    the component.
 */

.Grid {
  display: flex; /* 1 */
  flex-flow: row wrap;
  margin: 0; /* 1 */
  padding: 0; /* 1 */
}

/* With gutters */
.Grid--withGutter {
  margin: (@base-font-size * -1em) 0 0 (@base-font-size * -1em);
}
.Grid--withGutter > .Grid-cell {
  padding: (@base-font-size * 1em) 0 0 (@base-font-size * 1em);
}

/* Alignment per row */
.Grid--alignTop {
  align-items: flex-start;
}
.Grid--alignBottom {
  align-items: flex-end;
}
.Grid--alignCenter {
  align-items: center;
}

.Grid--justifyCenter {
  justify-content: center;
}

/* Alignment per cell */
.Grid-cell--alignTop {
  align-self: flex-start;
}
.Grid-cell--alignBottom {
  align-self: flex-end;
}
.Grid-cell--alignCenter {
  align-self: center;
}

/* Grid cell
   ========================================================================== */

/**
 * No explicit width by default. Rely on combining `Grid-cell` with a dimension
 * utility or a component class that extends 'Grid'.
 *
 * 1. Set flex items to full width by default
 * 2. Fix issue where elements with overflow extend past the
 *    `Grid-cell` container - https://git.io/vw5oF
 */

.Grid-cell {
  box-sizing: inherit;
  flex-basis: 100%; /* 1 */
  min-width: 0; /* 2 */
}

/**
 * Modifier: horizontally center one unit
 * Set a specific unit to be horizontally centered. Doesn't affect
 * any other units. Can still contain a child `Grid` object.
 */

.Grid-cell--center {
  margin: 0 auto;
}
