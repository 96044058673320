.c-mission-block {
  position: relative;
}

.c-mission-block__cell {
  flex: 1 1 0%;
}

.c-mission-block__group {
  color: #fff;
  display: block;
  min-height: 16rem;
  position: relative;
}

.c-mission-block__separator {
  background: #fff;
  display: none;
  position: absolute;
  z-index: 10;
}

.c-mission-block__group-text {
  left: 50%;
  position: absolute;
  top: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  width: 90%;
}

@media @mq-sm {
  .c-mission-block {
    display: flex;
  }

  .c-mission-block__cell {
    &:first-child .c-mission-block__group {
      clip-path: polygon(0 0, 100% 0, 88% 100%, 0 100%);
      margin-right: -6%;
    }

    &:nth-child(2) .c-mission-block__group {
      clip-path: polygon(12% 0, 100% 0, 100% 100%, 0 100%);
      margin-left: -6%;
    }
  }

  .c-mission-block__separator {
    display: block;
  }
}

@media @mq-md {
  .c-mission-block__group-text {
    .c-mission-block__cell:first-child & {
      bottom: 25%;
      left: auto;
      right: 6%; /* the negative margin value of the parent cell */
      text-align: right;
      top: auto;
      transform: translate(0, 0);
    }

    .c-mission-block__cell:nth-child(2) & {
      bottom: auto;
      left: 4%; /* two thirds the negative margin value of the parent cell */
      right: 0;
      text-align: left;
      top: 25%;
      transform: translate(0, 0);
    }
  }
}
