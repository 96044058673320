.Container {
	display: block;
	padding: 1rem;
	margin: 0 auto;
	max-width: @base-site-width;
	position: relative;
	width: 100%;
	z-index: 1;
	.u-cf;
}

.Container--wide {
	max-width: @wide-site-width;
}

.Container--full {
	max-width: none !important;
}

.Container--black {
	background: #000;

	> * {
		color: #fff;
	}

	> :first-child {
		margin-top: 0 !important;
	}

	.Subtitle {
		color: #fff;
	}

	.Box--card {
		border-color: #444;
	}

	.Media--connected {
		.MediaFigure {
			&::before {
				border-bottom-color: #000;
			}
		}

		.MediaBody + .MediaFigure {
			&::before {
				border-bottom-color: transparent;
				border-top-color: #000;
			}
		}
	}

	.Media--flag {
		.MediaFigure {
			&::before {
				border-bottom-color: transparent;
				border-right-color: #000;
			}
		}

		.MediaBody + .MediaFigure {
			&::before {
				border-bottom-color: transparent;
				border-left-color: #000;
			}
		}
	}

	.Media {
		h1,h2,h3,h4,h5,h6 {
			color: @green-light;
		}
	}
}

.Container--flushT {
	padding-top: 0 !important;
}

.Container--flushH {
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.Container--flush {
	padding: 0 !important;
}

.Container--flat {
	&::after,
	&::before {
		background: none;
	}
}

@media @mq-sm {
	.Container {
		padding: (@base-spacing-unit * 2.074) 1.2em;
	}

	.Container--black {
		.sm-Media--flag {
			.MediaFigure {
				&::before {
					border-color: transparent;
					border-right-color: #000;
				}
			}

			.MediaBody + .MediaFigure {
				&::before {
					border-color: transparent;
					border-left-color: #000;
				}
			}
		}
	}
}

@media @mq-md {
	.Container--black {
		.md-Media--flag {
			.MediaFigure {
				&::before {
					border-color: transparent;
					border-right-color: #000;
				}
			}

			.MediaBody + .MediaFigure {
				&::before {
					border-color: transparent;
					border-left-color: #000;
				}
			}
		}
	}
}
