.u-float-right { float: right; }
.u-float-left { float: left; }
.u-clearfix {
	&::after {
	  content: "";
	  display: table;
	  clear: both;
	}
}

@media @mq-sm {
	.sm\:u-float-right {
		float: right;
	}
}

@media @mq-md {
	.md\:u-float-right {
		float: right;
	}
}
