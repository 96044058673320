.Table {
	margin: 1em 0;
  width: 100%;
}

/* force tables into having equal-width columns */
.Table--fixed {
  table-layout: fixed;
}

/* tightly packed cells */
.Table--compact {
  th,
  td {
    padding: .402em;
  }
}


/* lightly packed cells */
.Table--cosy {
	th,
	td {
	  padding: .694em;
	}
}

/* widely padded cells */
.Table--comfy {
  th,
  td {
    padding: 1.44em;
  }
}

/* add borders to table rows */
.Table--rows {
	tbody {
		> tr:nth-child(odd) {
			th,
			td {
				background: rgba(0,0,0,.07);
			}
		}
	}
}


/* add borders to table columns */
.Table--columns {
  &,
  th,
  td {
    border: 0 solid @grey;
  }
    
  border-left-width: 1px;
    
  th,
  td {
    border-right-width: 1px;
  }
}

.TableOverflow {
	display: block;
	overflow-x: auto;
	width: 100%;
}