.Footer {
	background-position: left 50% bottom 60%;
  background-repeat: no-repeat;
  background-size: cover;
	padding: 50px 0;
	position: relative;
	
	&:before {
		background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
		content: '';
		display: block;
		height: 50%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		z-index :0;
	}
	
	.Sponsors {
		position: relative;
	}
}

@media @mq-sm {
	.Footer {
		padding-bottom: 125px;
	  padding-top: 125px;
	}
}