/**
 * Requires: obj.flex-embed.less
 * only if the CoverImage element does not have a height/width
 */

.CoverImage {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  margin: 0 auto;
  max-height: 660px;
  position: relative;
  width: 100%;

	.FlexEmbed-content {
		background-color: fade(#000, 50%);

		a {
			color: inherit;
		}
	}

	.FlexEmbed-content--noBg {
		background-color: transparent;
	}
}
